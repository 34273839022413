
// function getRandomShape() {
//   const shapes = ["ellipse", "diamond", "rectangle"];
//   return shapes[Math.floor(Math.random() * shapes.length)];
// }

// function getRandomColor() {
//   const colors = ["#eda4a3", "#e74c3c", "#a0a3f8"];
//   return colors[Math.floor(Math.random() * colors.length)];
// }

// function generateGenesAndEdges(genesData) {
//   const genes = [];
//   const edges = [];

//   for (let i = 0; i < genesData?.nodes?.length; i++) {
//     const gene = {
//       data: {
//         id: genesData?.nodes[i]?.data?.id,
//         label: genesData?.nodes[i]?.data?.id,
//         name: genesData?.nodes[i]?.data?.name,
//         shapeType: getRandomShape(),
//         colorType: getRandomColor(),
//       },
//     };
//     genes.push(gene);
//   }

//   // Establish random edges between genes
//   for (let i = 0; i < genesData?.edges?.length; i++) {
//     const edge = {
//       data: {
//         source: genesData?.edges[i]?.data?.source,
//         target: genesData?.edges[i]?.data?.target,
//         associationType:genesData?.edges[i]?.data?.association_type ? genesData?.edges[i]?.data?.association_type : "",
//       },
//     };
//     edges.push(edge);
//   }

//   return [...genes, ...edges];
// }

// // Export the functions
// export default generateGenesAndEdges;


// Function to get shape based on type
function getShapeByType(type) {
  const shapeMap = {
    cnv: "triangle",
    mrna: "circle",
    protein: "rectangle",
    mutation: "diamond",
    fusion: "hexagon",
    methylation: "octagon",
  };
  return shapeMap[type?.toLowerCase()] || "rectangle"; 
}

// Function to get color based on type
function getColorByType(type) {
  const colorMap = {
    cnv: "#ffa07a", //  Orange 
    mrna: "#8de6f6", // Blue 
    protein: "#42ae75", // Green 
    mutation:"#ff6347", //  Red 
    fusion: "#dda0dd", //  Purple
    methylation: "#fdd835", // Yellow
  };
  return colorMap[type?.toLowerCase()] || "#6495ed"; // default color
}

function generateGenesAndEdges(genesData) {
  const genes = [];
  const edges = [];

  for (let i = 0; i < genesData?.nodes?.length; i++) {
    const node = genesData?.nodes[i]?.data;
    const gene = {
      data: {
        id: node?.id,
        label: node?.id,
        name: node?.name,
        shapeType: getShapeByType(node?.type),
        colorType: getColorByType(node?.type),
      },
    };
    genes.push(gene);
  }

  for (let i = 0; i < genesData?.edges?.length; i++) {
    const edge = {
      data: {
        source: genesData?.edges[i]?.data?.source,
        target: genesData?.edges[i]?.data?.target,
        associationType: genesData?.edges[i]?.data?.association_type || "",
      },
    };
    edges.push(edge);
  }

  return [...genes, ...edges];
}

export default generateGenesAndEdges;
