import React, { useEffect } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import axiosInstance from "../configuration/Request";

const EditProject = ({ closeAlert, networkData, onEditHandler }) => {
  const [projectName, setProjectName] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  useEffect(() => {
    setProjectName(networkData.networkName || "");
  }, [networkData]);

  const handleEditProject = () => {
    if (!projectName.trim()) {
      setErrorMessage("Project name cannot be empty.");
      return;
    }

    const payload = {
      data: {
        project_name: projectName.trim(),
      },
    };

    axiosInstance
      .put(`${process.env.REACT_APP_BASE_URL}api/new-networks/${networkData.id}`, payload)
      .then(() => {
        onEditHandler("edit");
      })
      .catch((error) => {
        const apiErrorMessage = error?.response?.data?.message || "Something went wrong. Please try again later.";
        setErrorMessage(apiErrorMessage);
      });
  };

  const handleInputChange = (e) => {
    setProjectName(e.target.value);
    if (e.target.value.trim()) {
      setErrorMessage("");
    }
  };

  return (
    <div className="edit-popup">
      <div className="popup-header">Edit Name</div>
      <div className="button-popup-container">
        <div className="my-2">
          <FormGroup floating className="ms-1">
            <Input
              type="text"
              name="projectName"
              id="projectName"
              placeholder="Edit Project Name"
              style={{ height: "58px" }}
              value={projectName}
              onChange={handleInputChange}
            />
            <Label for="projectName">Edit Name</Label>
            <div className="error-message-edit">
            {errorMessage && <div className="text-danger mt-1">{errorMessage}</div>}
            </div>
          </FormGroup>
        </div>
        <button className="btn btn-lg btn-light btn-cancel" onClick={closeAlert}>
          Cancel
        </button>
        <button
          className="btn btn-lg btn-primary btn-delete"
          onClick={handleEditProject}
          // disabled={!projectName.trim()}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default EditProject;
