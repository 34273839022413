import React from "react";
import axiosInstance from "../configuration/Request";

const DeleteProject = ({ closeAlert,onDeleteHandler,deleteIdArray }) => {


  const handleDeleteProject = () => {
    let payload = {
      ids:deleteIdArray
    }
    axiosInstance.post(
        process.env.REACT_APP_BASE_URL + `api/delete-networks`,payload
      )
      .then((response) => {
        onDeleteHandler("delete");
      })
      .catch((error) => {
        console.log("Something went wrong.Please try after sometime.");
      });
  };
  return (
    <div className="delete-project-container">
      <div className="popup-header">
        Are you sure you want to delete this project?
      </div>
      <div className="button-popup-container">
        <button
          className="btn btn-lg btn-light btn-cancel"
          onClick={closeAlert}
        >
          Cancel
        </button>
        <button
          className="btn btn-lg btn-primary btn-delete"
          onClick={handleDeleteProject}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteProject;
