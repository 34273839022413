import React, { Fragment } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Row, Col } from "reactstrap";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TooltipComponent from "../ReusableComponent/TooltipComponent";
import Delete from "../../assets/images/svg/Delete.svg";
import Edit from "../../assets/images/svg/Edit.svg";
import View from "../../assets/images/svg/View.svg";
import Analysis from "../../assets/images/svg/Analysis.svg";
import download from "../../assets/images/svg/download.svg";

const mapStateToProps = (state) => {
  return {
    reduxData: state.counterSlice,
  };
};

class NetworkTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
    };
  }

  handleViewNetwork = (row) => {
    if (this.props.onView) {
      this.props.onView(row);
    }
  };

  handleFurtherAnalysis = (row) => {
    this.props.history.push("/app/discover/furtherAnalysis");
  };

  handleDelete = (row) => {
    if (this.props.onDelete) {
      this.props.onDelete(row);
    }
  };

  handleEdit = (row) => {
    if (this.props.onEdit) {
      this.props.onEdit(row);
    }
  };

  handleDownload = (row) => {
    if (this.props.onDownload) {
      this.props.onDownload(row);
    }
  };

  handleRowSelection = ({ selectedRows }) => {
    this.setState({ selectedRows });

    if (this.props.handleRowSelection) {
      this.props.handleRowSelection({ selectedRows });
    }
  };

  toggleTooltip = (key) => {
    this.setState((prevState) => ({
      tooltipOpen: {
        ...prevState.tooltipOpen,
        [key]: !prevState.tooltipOpen[key],
      },
    }));
  };


  render() {
    const columns = [
      {
        name: <span className="table-header">Status</span>,
        selector: (row) => row.status,
        sortable: true,
        cell: (row) => (
          <div
            className={`status-badge ${row.status
              .toLowerCase()
              .replace(" ", "-")}`}
          >
            {row.status}
          </div>
        ),
        width: "150px",
      },
      {
        name: <span className="table-header">Network Name</span>,
        selector: (row) => row.networkName,
        cell: (row) => (
          <TooltipComponent
          id={`tooltip-network-name-${row.id}`}
          tooltipText={row.networkName}
        >
            <div className="network-name-container">
              <span className="network-name">{row.networkName}</span>
            </div>
            </TooltipComponent>
         
        ),
        sortable: true,
      },
      {
        name: <span className="table-header">Submit Time</span>,
        selector: (row) => row.submitTime,
        sortable: true,
        width: "180px",
      },
      {
        name: <span className="table-header">Actions</span>,
        cell: (row) => (
          <div className="button-group-table">
            {row.status === "Done" && (
              <>
                <TooltipComponent
                  id={`tooltip-eye-${row.id}`}
                  tooltipText="View Network"
                >
                  <img
                    className="image-icon"
                    src={View}
                    alt=""
                    onClick={() => this.handleViewNetwork(row)}
                  />
                </TooltipComponent>
                <TooltipComponent
                  id={`tooltip-analysis-${row.id}`}
                  tooltipText="Further Analysis"
                >
                  <img
                    src={Analysis}
                    alt=""
                    className="image-icon"
                    onClick={() => this.handleFurtherAnalysis(row)}
                  />
                </TooltipComponent>
                <TooltipComponent
                  id={`tooltip-download-${row.id}`}
                  tooltipText="Download"
                >
                  <img
                    src={download}
                    alt=""
                    className="image-icon"
                     onClick={() => this.handleDownload(row)}
                  />
                </TooltipComponent>
              </>
            )}
            {(row.status === "Expired" ||
              row.status === "Running" ||
              row.status === "Done") && (
              <>
                <TooltipComponent
                  id={`tooltip-edit-${row.id}`}
                  tooltipText="Edit"
                >
                  <img
                    className="image-icon"
                    src={Edit}
                    alt=""
                    onClick={() => this.handleEdit(row)}
                  />
                </TooltipComponent>
                <TooltipComponent
                  id={`tooltip-delete-${row.id}`}
                  tooltipText="Delete"
                >
                  <img
                    className="image-icon"
                    src={Delete}
                    alt=""
                    onClick={() => this.handleDelete(row)}
                  />
                </TooltipComponent>
              </>
            )}
          </div>
        ),
        width: "275px",
      },
    ];

    return (
      <>
        <Fragment>
          <TransitionGroup>
            <CSSTransition
              component="div"
              classNames="TabsAnimation"
              appear={true}
              timeout={1500}
              enter={false}
              exit={false}
            >
              <div>
                <Row>
                  <Col md="12">
                    <DataTable
                      columns={columns}
                      data={this.props.networkDetails}
                      selectableRows
                      // selectableRowDisabled={(row) => row.status !== "Done"}
                      selectableRowDisabled={(row) =>
                        // (this.state.selectedRows.length >= 2 &&
                        //   !this.state.selectedRows.some(
                        //     (selectedRow) => selectedRow.id === row.id
                        //   )) ||
                        row.status !== "Done"
                      }
                      onSelectedRowsChange={this.handleRowSelection}
                      pagination // enable pagination
                      paginationServer // enable server-side pagination
                      paginationTotalRows={this.props.total} // total number of rows
                      paginationPerPage={this.props.pageSize} // number of items per page
                      paginationRowsPerPageOptions={[10, 50, 100]} // pagination options
                      onChangePage={this.props.onPageChange} // handle page change
                      onChangeRowsPerPage={this.props.onRowsPerPageChange} // fetch first page with new page size
                    />
                  </Col>
                </Row>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </Fragment>
      </>
    );
  }
}

export default connect(mapStateToProps)(withRouter(NetworkTable)); // Wrap withRouter
