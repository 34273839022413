import React, { Component } from 'react';
import FileViewer from 'react-file-viewer';


class FileViewerItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textContent: null,
            refrencesList: props?.refrencesList ?? [],

        };

    }

    componentDidMount() {
        if (this.props.fileType === 'text/plain') {
            this.loadTextContent();
        }
    }

    checkForCitations(content) {
        const { refrencesList } = this.state;

        refrencesList.forEach(reference => {
            if (content) {
                const citationRegex = new RegExp(reference?.attributes?.citation?.replace("[", "\\[").replace("]", "\\]"), "g");
                if (citationRegex.test(content)) {
                    this.updateCSS(reference.id);
                }
            }
        });
    }

    updateCSS(referenceId) {
        // Update CSS to make the citation look like a link
        const citationElements = document.getElementsByClassName(`citation-${referenceId}`);
        Array.from(citationElements).forEach(citationElement => {
            citationElement.style.color = '#376faa';
            citationElement.style.cursor = 'pointer';
            citationElement.addEventListener('click', () => this.handleCitationClick(referenceId));
        });
    }

    handleCitationClick(referenceId) {
        // Call a function when the citation is clicked

        this.props?.handleClickScroll(referenceId)
    };

    renderHTMLReferenceCheck(content) {
        this.checkForCitations(content)
        return (
            <div>
                {content?.split(/(\(.*?\))/).map((part, index) => {
                    const reference = this.state.refrencesList.find(ref => ref.attributes.citation === part);
                    if (reference) {
                        return (
                            <span
                                key={index}
                                className={`citation-${reference.id}`}
                            >
                                {reference.attributes.citation}
                            </span>
                        );
                    } else {
                        const regex = /(?:IC|EC)50/g;
                        // To Replace the matched pattern with the number "50" wrap it in <sub> tag
                        const updatedContent = part?.replace(regex, (match) => {
                            return match.replace("50", "<sub>50</sub>");
                        });
                        const jsxText = <div style={{ display: "inline" }} dangerouslySetInnerHTML={{ __html: updatedContent }} />;
                        return jsxText;
                    }
                })}
            </div>
        );


    }

    loadTextContent = () => {
        fetch(this.props.url)
            .then((response) => response.text())
            .then((textContent) => {
                this.setState({ textContent: textContent })
            })
            .catch((error) => console.error('Error loading text content:', error));
    };

    render() {
        const { url, fileType } = this.props;
        const { textContent } = this.state;

        return (
            <div>
                {fileType === 'text/plain' ? (
                    <div className='RMAcontainer'>
                        {this.renderHTMLReferenceCheck(textContent)}
                    </div>
                ) : (
                    <FileViewer
                        fileType={fileType}
                        filePath={url}
                        onError={(err) => console.log(err)}
                    />
                )}
            </div>
        );
    }
}

export default FileViewerItem;

// export default EmbeddedContent;