import React, { Component } from "react";
import { FormGroup, Button, Label, Input } from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import axiosInstance from "../configuration/Request";
import Loader from "react-loaders";
import {
  setNetworkAnalysisDetails,
  setNetworkSuccess,
} from "../../reducers/counterSlice";
import backlogo from "../../assets/images/svg/Back.svg";
import { Link } from "react-router-dom";
import AutoSuggestSearch from "../Components/AutoSuggestSearch";
import { ToastContainer } from "react-toastify";
import { showErrorToast } from "../Components/Toaster";
import "react-toastify/dist/ReactToastify.css";
import SweetAlert from "react-bootstrap-sweetalert";
import ErrorPopup from "../Components/ErrorPopup";

const mapStateToProps = (state) => {
  return {
    reduxData: state.counterSlice,
  };
};

const mapDispatchToProps = {
  setNetworkAnalysisDetails,
  setNetworkSuccess,
};

class ParameterSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      zTestOptions: [
        { value: 0.05, label: "0.05" },
        { value: 0.01, label: "0.01" },
      ],
      precisionThresholdValue: "",
      isValidPrecision: true,
      scopeThresholdValue: "",
      isValidScope: true,
      focuseGeneValues: [],
      zTestError: "",
      loader: false,
      suggestions: [],
      selectedAssociateType: null,
      associateTypeOptions: [],
      associateTypeError: "",
      isFocuseGeneDisable: true,
      focusedGenesList: [],
      openErrorPopup:false
    };

    this.formContainerRef = React.createRef();
  }

  // focusedGenesList = [
  //   "BRCA1",
  //   "BRCA2",
  //   "TP53",
  //   "EGFR",
  //   "PTEN",
  //   "KRAS",
  //   "PIK3CA",
  //   "MYC",
  //   "CDKN2A",
  //   "RB1",
  // ];

  componentDidMount() {
    
  let payload = {
    data :{
      cancer_type:
      this.props?.reduxData?.networkAnalysisDetails?.cancer_type,
    histological_subtype: this.props?.reduxData?.networkAnalysisDetails?.histological_subtype.value,
    source_gene_level:
      this.props?.reduxData?.networkAnalysisDetails?.source_gene,
    target_gene_level:
      this.props?.reduxData?.networkAnalysisDetails?.target_gene,
    normal_tumor:
      this.props?.reduxData?.networkAnalysisDetails?.normal_tumor.toLowerCase(),
    sample_type:
      this.props?.reduxData?.networkAnalysisDetails?.sample_type?.value,
    risk_level:
      this.props?.reduxData?.networkAnalysisDetails?.risk_level?.value,
    therapeutic_response:
      this.props?.reduxData?.networkAnalysisDetails?.therapeutic_response
        ?.label,
    bootstrap_yes_no:
      this.props?.reduxData?.networkAnalysisDetails?.bootstrap_yes_no,
    data_source:
      this.props?.reduxData?.networkAnalysisDetails?.data_source?.value,
    }
  }
    this.setState({ loader: true });
    axiosInstance
      .post(
        `${process.env.REACT_APP_BASE_URL}api/association-type`,payload
      )
      .then((res) => {
        const association = res?.data?.map((item) => ({
          value: item,
          label: item,
        }));
        if(association.length <= 0){
          this.setState({ openErrorPopup: true });
        }
        this.setState({ associateTypeOptions: association });
      })

      .catch((err) => {
        showErrorToast("Error while fetching association type.");
      })
      .finally(() => {
        this.setState({ loader: false });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedOption, selectedAssociateType } = this.state;

    if (
      prevState.selectedOption !== selectedOption ||
      prevState.selectedAssociateType !== selectedAssociateType
    ) {
      this.fetchFocusGeneSuggestions();
    }
  }

  handleOnBlur = () => {
    this.fetchFocusGeneSuggestions();
  };

  fetchFocusGeneSuggestions = () => {
    const {
      selectedOption,
      precisionThresholdValue,
      scopeThresholdValue,
      selectedAssociateType,
    } = this.state;

    if (!selectedAssociateType) {
      this.setState({
        isFocuseGeneDisable: true,
        focuseGeneValues: [],
      });
      return;
    }
    if (!selectedOption && (!precisionThresholdValue || !scopeThresholdValue)) {
      this.setState({
        isFocuseGeneDisable: true,
        focuseGeneValues: [],
      });
      return;
    }

    const payload = {
      data: {
        cancer_type:
          this.props?.reduxData?.networkAnalysisDetails?.cancer_type,
        histological_subtype: this.props?.reduxData?.networkAnalysisDetails?.histological_subtype.value,
        source_gene_level:
          this.props?.reduxData?.networkAnalysisDetails?.source_gene,
        target_gene_level:
          this.props?.reduxData?.networkAnalysisDetails?.target_gene,
        normal_tumor:
          this.props?.reduxData?.networkAnalysisDetails?.normal_tumor.toLowerCase(),
        sample_type:
          this.props?.reduxData?.networkAnalysisDetails?.sample_type?.value,
        risk_level:
          this.props?.reduxData?.networkAnalysisDetails?.risk_level?.value,
        therapeutic_response:
          this.props?.reduxData?.networkAnalysisDetails?.therapeutic_response
            ?.label,
        bootstrap_yes_no:
          this.props?.reduxData?.networkAnalysisDetails?.bootstrap_yes_no,
        data_source:
          this.props?.reduxData?.networkAnalysisDetails?.data_source?.value,
        association_type: this.state.selectedAssociateType.value,
        //update
        ...(this.state.focuseGeneValues?.length > 0 && {
          focused_genes: this.state.focuseGeneValues,
        }),
        ...(this.state.selectedOption && {
          p_value: this.state.selectedOption.value,
        }),
        ...(this.state.scopeThresholdValue && {
          scope: Number(this.state.scopeThresholdValue),
        }),
        ...(this.state.precisionThresholdValue && {
          precision: Number(this.state.precisionThresholdValue),
        }),
      },
    };
    
    this.setState({
      isFocuseGeneDisable: false,
      loader: true
    });
    axiosInstance
      .post(
        `${
          process.env.REACT_APP_BASE_URL
        }api/focused-genes?`,payload
      )
      .then((res) => {
        if(res.data.unique_genes.length <= 0){
          showErrorToast("Focused genes not found for selections",false);
        }

        this.setState({ focusedGenesList: res.data.unique_genes });
      })

      .catch((err) => {
        showErrorToast("Error while fetching Focuse gene.",false);
      })
      .finally(() => {
        this.setState({ loader: false });
      });
  };

  handleSelectChange = (event, type) => {
    if (type === "z-test") {
      this.setState({
        selectedOption: event,
        zTestError: "",
        isValidPrecision: true,
        isValidScope: true,
      });
    } else if (type === "association type") {
      this.setState({ selectedAssociateType: event, associateTypeError: "" });
    }
  };

  handleInputChange = (e, type) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9.]/g, "");
    if (type === "precision") {
      this.setState({
        precisionThresholdValue: numericValue,
        isValidPrecision: true,
        zTestError: "",
      });
    } else if (type === "scope") {
      this.setState({
        scopeThresholdValue: numericValue,
        isValidScope: true,
        zTestError: "",
      });
    }
  };

  handleFocusGeneTag = (focuseGene) => {
    this.setState({
      focuseGeneValues: focuseGene,
    });
  };

  handleSuggestionsUpdate = () => {
    if (this.formContainerRef.current) {
      this.formContainerRef.current.scrollTop =
        this.formContainerRef.current.scrollHeight;
    }
  };

  onSubmitClick = () => {
    const { selectedOption, precisionThresholdValue, scopeThresholdValue } =
      this.state;

    let hasError = false;
    if (!this.state.selectedAssociateType) {
      this.setState({ associateTypeError: "Please select a Associate Type." });
      hasError = true;
    }
    if (!selectedOption && !precisionThresholdValue && !scopeThresholdValue) {
      this.setState({
        zTestError: "Please select Z-test or fill in both thresholds.",
        isValidPrecision: false,
        isValidScope: false,
      });
      hasError = true;
    } else if (!selectedOption) {
      if (!precisionThresholdValue || !scopeThresholdValue) {
        this.setState({
          isValidPrecision: !!precisionThresholdValue,
          isValidScope: !!scopeThresholdValue,
          zTestError: "",
        });
        hasError = true;
      }
    }

    if (!hasError) {
      // If there are no errors, clear any error states
      this.setState({
        zTestError: "",
        isValidPrecision: true,
        isValidScope: true,
      });
      const payload = {
        data: {
          cancer_type:
            this.props?.reduxData?.networkAnalysisDetails?.cancer_type,
          histological_subtype: this.props?.reduxData?.networkAnalysisDetails?.histological_subtype?.value,
          source_gene_level:
            this.props?.reduxData?.networkAnalysisDetails?.source_gene,
          target_gene_level:
            this.props?.reduxData?.networkAnalysisDetails?.target_gene,
          normal_tumor:
            this.props?.reduxData?.networkAnalysisDetails?.normal_tumor.toLowerCase(),
          sample_type:
            this.props?.reduxData?.networkAnalysisDetails?.sample_type?.value,
          risk_level:
            this.props?.reduxData?.networkAnalysisDetails?.risk_level?.value,
          therapeutic_response:
            this.props?.reduxData?.networkAnalysisDetails?.therapeutic_response
              ?.label,
          bootstrap_yes_no:
            this.props?.reduxData?.networkAnalysisDetails?.bootstrap_yes_no,
          data_source:
            this.props?.reduxData?.networkAnalysisDetails?.data_source?.value,
          association_type: this.state.selectedAssociateType.value,
          ...(this.state.focuseGeneValues?.length > 0 && {
            focused_genes: this.state.focuseGeneValues,
          }),
          ...(this.state.selectedOption && {
            p_value: this.state.selectedOption.value,
          }),
          ...(this.state.scopeThresholdValue && {
            scope: Number(this.state.scopeThresholdValue),
          }),
          ...(this.state.precisionThresholdValue && {
            precision: Number(this.state.precisionThresholdValue),
          }),
        },
      };
      this.setState({ loader: true });
      axiosInstance
        .post(process.env.REACT_APP_BASE_URL + `api/new-networks`, payload)
        .then((response) => {
          this.props.setNetworkAnalysisDetails({});
          this.props.setNetworkSuccess(true);
          this.props.history.push("/app/discover/myProject");
        })
        .catch((error) => {
          if (error?.response?.data?.error?.message) {
            showErrorToast(error?.response?.data?.error?.message);
          } else {
            showErrorToast("Something went wrong.Not able to create network.",false);
          }
        })
        .finally(() => {
          this.setState({ loader: false });
        });
    }
  };

  closePopup = () => {
    this.props.history.push("/app/discover/newNetwork");
    this.setState({ openErrorPopup: false });
  };


  render() {
    const {
      selectedOption,
      zTestError,
      selectedAssociateType,
      associateTypeError,
    } = this.state;
    const disableThresholdFields = !!selectedOption;
    const disableZTest =
      !!this.state.precisionThresholdValue || !!this.state.scopeThresholdValue;

    return (
      <div className="outer-container">
        <ToastContainer />
        <div className="main-container">
          <div className="top-container">
            <Link
              className="btn-link-primary "
              to={{ pathname: "/app/discover/newNetwork" }}
            >
              <div className="d-inline-flex">
                <img src={backlogo} alt="" />
                <span
                  style={{ color: "#333333", fontWeight: 400 }}
                  className="ms-2"
                >
                  Back
                </span>
              </div>
            </Link>
            <div className="form-header">Significance Level</div>
            <div className="network-form-container" ref={this.formContainerRef}>
              <div className="previous-data">
                {[
                  { label: "Cancer Type", key: "cancer_type" },
                  { label: "Subtype", key: "histological_subtype" },
                  { label: "Normal/Tumor", key: "normal_tumor" },
                  { label: "Source Gene", key: "source_gene" },
                  { label: "Target Gene", key: "target_gene" },
                  { label: "Data Source", key: "data_source" },
                  { label: "Sample Type", key: "sample_type" },
                  { label: "Risk Level", key: "risk_level" },
                  {
                    label: "Therapeutic Response",
                    key: "therapeutic_response",
                  },
                  { label: "The bootstrapping", key: "bootstrap_yes_no" },
                ].map((item, index) => (
                  <div className="input-title me-2" key={index}>
                    {item.label} :{" "}
                    <span style={{ color: "#495057" }}>
                      {this.props?.reduxData?.networkAnalysisDetails?.[item.key]
                        ?.label ||
                        this.props?.reduxData?.networkAnalysisDetails?.[
                          item.key
                        ]}
                    </span>
                  </div>
                ))}
              </div>

              <div>
                <div className="input-title">Association Type*</div>
                <FormGroup className="associate-select">
                  <Select
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        height: "48px",
                        cursor: "pointer",
                        borderColor: associateTypeError
                          ? "red"
                          : baseStyles.borderColor,
                      }),
                      option: (base) => ({
                        ...base,
                        cursor: "pointer",
                      }),
                    }}
                    value={selectedAssociateType}
                    onChange={(e) =>
                      this.handleSelectChange(e, "association type")
                    }
                    options={this.state.associateTypeOptions}
                    placeholder="Select Associate Type..."
                  />
                  <div className="error-message">
                    {associateTypeError && (
                      <div className="text-danger mt-1">
                        {associateTypeError}
                      </div>
                    )}
                  </div>
                </FormGroup>
              </div>
              <div className="paramete-top-container d-flex">
                <div className="z-test-container">
                  <div className="input-title">Z-tests p*</div>
                  <FormGroup className="select-z-test">
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          height: "51px",
                          cursor: "pointer",
                          borderColor: zTestError
                            ? "red"
                            : baseStyles.borderColor,
                        }),
                        option: (base) => ({
                          ...base,
                          cursor: "pointer",
                        }),
                      }}
                      value={selectedOption}
                      onChange={(e) => this.handleSelectChange(e, "z-test")}
                      options={this.state.zTestOptions}
                      placeholder="Select Z - test p <*"
                      isDisabled={disableZTest}
                      isClearable
                    />
                    <div className="error-message">
                      {zTestError && (
                        <div className="text-danger">{zTestError}</div>
                      )}
                    </div>
                  </FormGroup>
                </div>

                <div className="or-container">Or</div>
                <div className="input-container">
                  <div className="input-title">Precision Threshold*</div>

                  <FormGroup floating className="input-field-container">
                    <Input
                      type="text"
                      name="Precision Threshold"
                      id="precisionThreshold"
                      style={{ height: "52px" }}
                      placeholder="Precision Threshold"
                      value={this.state.precisionThresholdValue}
                      onChange={(e) => this.handleInputChange(e, "precision")}
                      invalid={!this.state.isValidPrecision}
                      disabled={disableThresholdFields}
                      onBlur={this.handleOnBlur}
                    />
                    <Label for="examplePrecision">Precision Threshold*</Label>
                    <div className="error-message">
                      {!this.state.isValidPrecision && !zTestError && (
                        <div className="text-danger">
                          Please provide a valid Precision Threshold.
                        </div>
                      )}
                    </div>
                  </FormGroup>
                </div>
                <div className="input-container ">
                  <div className="input-title ms-3">Scope Threshold*</div>
                  <FormGroup floating className="scope-container">
                    <Input
                      type="text"
                      name="Scope Threshold"
                      id="scopeThreshold"
                      placeholder="Scope Threshold"
                      style={{ height: "52px" }}
                      value={this.state.scopeThresholdValue}
                      onChange={(e) => this.handleInputChange(e, "scope")}
                      invalid={!this.state.isValidScope}
                      disabled={disableThresholdFields}
                      onBlur={this.handleOnBlur}
                    />
                    <Label for="examplScope">scope Threshold*</Label>
                    <div className="error-message">
                      {!this.state.isValidScope && !zTestError && (
                        <div className="text-danger">
                          Please provide a valid Scope Threshold.
                        </div>
                      )}
                    </div>
                  </FormGroup>
                </div>
              </div>
              <hr style={{ width: "100%" }} />
              <div className="input-title ms-1">Focused Genes</div>
              <AutoSuggestSearch
                suggestions={this.state.focusedGenesList}
                disabled={this.state.isFocuseGeneDisable}
                onTagChange={this.handleFocusGeneTag}
                onSuggestionsUpdate={this.handleSuggestionsUpdate}
              />
            </div>
          </div>
          <div className="bottom-container mt-2">
            <Button
              className="cancel-button"
              color="light"
              //  onClick={() => this.handleCancel()}
            >
              Download Genome Scale
            </Button>
            <Button
              className="run-button ms-2"
              color="primary"
              onClick={this.onSubmitClick}
            >
              Run
            </Button>
          </div>
        </div>
        {this.state.loader && (
          <div className="backdrop">
            <div className="loader">
              <Loader type="ball-pulse-rise" />
            </div>
          </div>
        )}
          <SweetAlert
            custom
            show={this.state.openErrorPopup}
            customButtons={<React.Fragment></React.Fragment>}
            style={{ width: "auto", padding: "1rem" }}
          >
            <ErrorPopup
              closeAlert={this.closePopup}
              title="For selected values associate types are not available please change selection"
              buttonTitle="Close"
            ></ErrorPopup>
          </SweetAlert>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ParameterSetting);
