import React, { Component } from "react";
import { connect } from "react-redux";
import { FormGroup, Button } from "reactstrap";
import Select from "react-select";
import NetworkTable from "./networkTable";
import Loader from "react-loaders";
import axiosInstance from "../configuration/Request";
import { formatDate } from "../../utils/dateForamt";
import SweetAlert from "react-bootstrap-sweetalert";
import DeleteProject from "./deleteProject";
import EditProject from "./EditProject";
import { ToastContainer } from "react-toastify";
import { showSuccessToast, showErrorToast } from "../Components/Toaster";
import "react-toastify/dist/ReactToastify.css";
import { setNetworkSuccess, setCytoscape } from "../../reducers/counterSlice";
import Delete from "../../assets/images/svg/Delete.svg";
import { RiDeleteBin6Line } from "react-icons/ri";

const mapStateToProps = (state) => {
  return {
    reduxData: state.counterSlice,
  };
};

const mapDispatchToProps = {
  setNetworkSuccess,
  setCytoscape,
};

class MyProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCombineNetwork: null,
      networkData: [],
      // networkData: [
      //   {
      //     id: 2,
      //     status: "Running",
      //     networkName: "Join discussions and collaborate with peers. Free access to the basic features for a single time usage. Free access to the basic features for a single time usage.",
      //     submitTime: "9/27/2023 11:00 pm",
      //   },
      //   {
      //     id: 3,
      //     status: "Done",
      //     networkName: "Sample Task 3",
      //     submitTime: "9/27/2023 17:00 pm",
      //   },
      //   {
      //     id: 4,
      //     status: "Expired",
      //     networkName: "Sample Task 4",
      //     submitTime: "9/17/2023 17:00 pm",
      //   },
      //   {
      //     id: 5,
      //     status: "Done",
      //     networkName: "Sample Task 5",
      //     submitTime: "9/7/2023 15:00 pm",
      //   },
      //   {
      //     id: 6,
      //     status: "Done",
      //     networkName: "Sample Task 6",
      //     submitTime: "9/7/2023 15:00 pm",
      //   },
      //   {
      //     id: 6,
      //     status: "Done",
      //     networkName: "Sample Task 6",
      //     submitTime: "9/7/2023 15:00 pm",
      //   },
      //   {
      //     id: 6,
      //     status: "Done",
      //     networkName: "Sample Task 6",
      //     submitTime: "9/7/2023 15:00 pm",
      //   },
      //   {
      //     id: 6,
      //     status: "Done",
      //     networkName: "Sample Task 6",
      //     submitTime: "9/7/2023 15:00 pm",
      //   },
      // ],
      loader: false,
      CombineNetworkOptions: [
        { value: "Differential Network", label: "Differential Network" },
        { value: "Overlapped Network", label: "Overlapped Network" },
        { value: "Union Network", label: "Union Network" },
      ],
      openDeletePopup: false,
      openEditPopup: false,
      seletedRowForEdit: {},
      seletedRowDelete: [],
      page: 1,
      pageSize: 10,
      pageCount: 0, // total number of pages (from API response)
      total: 0, // total items (from API response)
      selectedNetwork: [],
      isDisabledRun: true,
      operationError: "",
      graphKey: Date.now(),
    };
  }

  componentDidMount() {
    this.fetchNetworkTableDetails(this.state.page, this.state.pageSize);
  }

  fetchNetworkTableDetails = (page, pageSize) => {
    this.setState({ loader: true });
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASE_URL}api/new-networks?pagination[page]=${page}&pagination[pageSize]=${pageSize}`
      )
      .then((res) => {
        const networks = res?.data?.data?.map((item) => ({
          id: item?.id,
          status: item.attributes?.status ? item.attributes?.status : "-",
          networkName: item?.attributes?.project_name
            ? item?.attributes?.project_name
            : "-",
          submitTime: item?.attributes?.createdAt
            ? formatDate(item?.attributes?.createdAt)
            : "-",
        }));
        this.setState({
          networkData: networks,
          page: res?.data?.meta?.pagination?.page,
          pageSize: res?.data?.meta?.pagination?.pageSize,
          pageCount: res?.data?.meta?.pagination?.pageCount,
          total: res?.data?.meta?.pagination?.total,
        });
      })

      .catch((err) => {
        if (err?.response?.data?.error?.message) {
          showErrorToast(err?.response?.data?.error?.message, false);
        } else {
          showErrorToast("Error while fetching network", false);
        }
      })
      .finally(() => {
        this.setState({
          loader: false,
          selectedNetwork: [],
          selectedCombineNetwork: null,
        });
        if (this.props.reduxData.networkSuccess) {
          showSuccessToast("Network created successfully!");
          this.props.setNetworkSuccess(false);
        }
      });
  };

  errorHandling = (selectedNetwork, combineNetwork) => {
    if (
      selectedNetwork.length > 0 &&
      selectedNetwork.length < 2 &&
      combineNetwork
    ) {
      this.setState({
        operationError:
          "Please select atleast two networks to perform operation",
      });
    } else if (selectedNetwork.length > 2 && combineNetwork) {
      this.setState({
        operationError: "Please select only two networks to perform operation",
      });
    } else {
      this.setState({
        operationError: "",
      });
    }
  };

  handleChange = (event) => {
    this.setState({ selectedCombineNetwork: event });
    this.errorHandling(this.state.selectedNetwork, event);
  };

  handheNewNetwork = () => {
    this.props.history.push("/app/discover/newNetwork");
  };

  handheRunOperations = () => {
    if (!this.state.selectedCombineNetwork) {
      this.setState({
        operationError: "Please select network operation dropdown value",
      });
      return;
    }
    let payload = {
      input_file_1: this.state.selectedNetwork[0].id,
      input_file_2: this.state.selectedNetwork[1].id,
      operation_type: this.state.selectedCombineNetwork?.value,
    };
    this.setState({ loader: true });
    axiosInstance
      .post(process.env.REACT_APP_BASE_URL + `api/network-operations`, payload)
      .then((response) => {
        this.fetchNetworkTableDetails(this.state.page, this.state.pageSize);
        this.setState({ graphKey: Date.now() });
        showSuccessToast("Combination network created successfully!");
      })
      .catch((error) => {
        if (error?.response?.data?.error?.message) {
          showErrorToast(error.response.data.error.message);
        } else {
          showErrorToast(
            "Something went wrong.Not able to create combination networks."
          );
        }
      })
      .finally(() => {
        this.setState({ loader: false });
        this.fetchNetworkTableDetails(this.state.page, this.state.pageSize);
      });
  };

  handleDownload = (rowData) => {
    this.setState({ loader: true });
    axiosInstance
      .get(`${process.env.REACT_APP_BASE_URL}api/download?id=${rowData.id}`, {
        responseType: "blob",
      })
      .then((res) => {
        const blob = new Blob([res.data], {
          type: res.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = rowData.networkName || "downloaded_file.txt";
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch((err) => {
        showErrorToast("Error while downloading network");
      })
      .finally(() => {
        this.setState({ loader: false });
      });
  };

  handleEditEvent = (rowData) => {
    this.setState({ seletedRowForEdit: rowData, openEditPopup: true });
  };

  handleMultiDelete = () => {
    const selectedIds = this.state.selectedNetwork.map((element) => element.id);
    this.setState({ seletedRowDelete: selectedIds, openDeletePopup: true });
  };

  handleDeleteEvent = (rowData) => {
    this.setState({ seletedRowDelete: [rowData.id], openDeletePopup: true });
  };

  handleDeleteEditSuccess = (action) => {
    const message =
      action === "delete"
        ? "Network deleted successfully"
        : `Network name updated successfully`;
    this.setState({
      seletedRowForEdit: {},
      seletedRowDelete: [],
      openDeletePopup: false,
      openEditPopup: false,
    });
    showSuccessToast(message);
    this.fetchNetworkTableDetails(this.state.page, this.state.pageSize);
  };

  closePopup = () => {
    this.setState({
      seletedRowForEdit: {},
      seletedRowDelete: [],
      openDeletePopup: false,
      openEditPopup: false,
    });
  };

  handleView = (rowData) => {
    console.log(rowData);
    this.props.setCytoscape({
      cytoscapeID: rowData.id,
    });
    this.props.history.push("/app/discover/viewNetwork");
  };

  rowSelectionHandler = (selectedRow) => {
    this.setState({
      selectedNetwork: selectedRow?.selectedRows,
      operationError: "",
    });
    if (selectedRow?.selectedRows.length <= 0) {
      this.setState({ selectedCombineNetwork: null, operationError: "" });
    }
    this.errorHandling(
      selectedRow?.selectedRows,
      this.state.selectedCombineNetwork
    );
  };

  handlePageChange = (page) => {
    this.setState({ page });
    this.fetchNetworkTableDetails(page, this.state.pageSize);
  };

  handleRowsPerPageChange = (newPerPage) => {
    this.setState({ pageSize: newPerPage, page: 1 }, () => {
      this.fetchNetworkTableDetails(1, newPerPage);
    });
  };

  render() {
    const { selectedCombineNetwork, operationError } = this.state;
    const isDisabled = this.state.selectedNetwork.length <= 0;
    const isDisabledRun = this.state.selectedNetwork.length !== 2;
    return (
      <div className="network-container-outer">
        <div className="network-container">
          <ToastContainer />
          <div className="top-div flex-wrap">
            <div className="title-div fw-bold">My Projects</div>
            <Button
              className="create-button-parameter"
              color="primary"
              onClick={this.handheNewNetwork}
            >
              Create New Network
            </Button>
          </div>

          <div className="network-button-container">
            <FormGroup className="select-dropdown-network">
              <Select
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    cursor: "pointer",
                    // width: "250px",
                  }),
                  option: (base) => ({
                    ...base,
                    cursor: "pointer",
                  }),
                }}
                value={selectedCombineNetwork}
                onChange={this.handleChange}
                options={this.state.CombineNetworkOptions}
                placeholder="Network Operations"
                isDisabled={isDisabled}
              />
            </FormGroup>
            <Button
              className="combine-network-button"
              color="primary"
              disabled={isDisabledRun}
              onClick={this.handheRunOperations}
            >
              Run Operations
            </Button>
            <Button
              className=" delete-button-div"
              color="danger"
              onClick={this.handleMultiDelete}
              disabled={this.state.selectedNetwork.length <= 0}
            >
              <RiDeleteBin6Line size={18} />
            </Button>
          </div>
          <div className="error-message-operation">
            {operationError && (
              <div className="text-danger">{operationError}</div>
            )}
          </div>

          <div className="network-table">
            <NetworkTable
              key={this.state.graphKey}
              networkDetails={this.state.networkData}
              onEdit={this.handleEditEvent}
              onDelete={this.handleDeleteEvent}
              onView={this.handleView}
              onDownload={this.handleDownload}
              handleRowSelection={this.rowSelectionHandler}
              page={this.state.page}
              pageSize={this.state.pageSize}
              pageCount={this.state.pageCount}
              total={this.state.total}
              onPageChange={this.handlePageChange}
              onRowsPerPageChange={this.handleRowsPerPageChange}
            />
          </div>
          {this.state.loader && (
            <div className="backdrop">
              <div className="loader">
                <Loader type="ball-pulse-rise" />
              </div>
            </div>
          )}

          <SweetAlert
            custom
            show={this.state.openEditPopup}
            customButtons={<React.Fragment></React.Fragment>}
            style={{ width: "auto", padding: "1rem" }}
          >
            <EditProject
              closeAlert={this.closePopup}
              onEditHandler={this.handleDeleteEditSuccess}
              networkData={this.state.seletedRowForEdit}
            ></EditProject>
          </SweetAlert>

          <SweetAlert
            custom
            show={this.state.openDeletePopup}
            customButtons={<React.Fragment></React.Fragment>}
            style={{ width: "auto", padding: "1rem" }}
          >
            <DeleteProject
              closeAlert={this.closePopup}
              onDeleteHandler={this.handleDeleteEditSuccess}
              deleteIdArray={this.state.seletedRowDelete}
            ></DeleteProject>
          </SweetAlert>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProject);
