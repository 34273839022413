import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Button, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { setloginDetails, setBasicDetails, setPrimaryRole, setRegistrationEmail, setCancerType, setForgetPasswordEmail, setSubscriptionDetail, setloginSubscriptionDetail, setupgradePopup } from "../../../reducers/counterSlice";
import { connect } from 'react-redux';
import Loader from "react-loaders";
import axiosInstance from "../../configuration/Request";
import axios from "axios";
import { encryptPassword } from "../../../utils/passwordEncryption";

const mapStateToProps = (state) => {

  return {
    reduxData: state.counterSlice
  };
};
const mapDispatchToProps = {
  setloginDetails, setBasicDetails, setPrimaryRole, setRegistrationEmail, setCancerType, setForgetPasswordEmail, setSubscriptionDetail, setloginSubscriptionDetail, setupgradePopup

};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginEmail: "",
      loginPassword: "",
      LoginInvalidMessage: '',
      loader: false,
      isValidEmail: true,
      isValidPassword: true,
      roleList: [],
    };
  };

  componentDidMount = () => {
    this.props?.setloginDetails('')
    this.props?.setBasicDetails({})
    this.props?.setPrimaryRole({})
    this.props?.setRegistrationEmail('')
    this.props?.setCancerType({})
    this.props?.setForgetPasswordEmail('')
    this.props?.setSubscriptionDetail({})
    this.props?.setloginSubscriptionDetail({})
    this.props?.setupgradePopup(false);

    axiosInstance
      .get(process.env.REACT_APP_BASE_URL + `api/users-permissions/roles`)
      .then((res) => {

        this.setState({ roleList: res?.data?.roles });
      })
      .catch((err) => {
        console.error(" error:", err);
      })
      .finally(() => {
      });
  }

  authenticationLogin = async(e) => {

    try {
      this.setState({ loader: true });

      const encryptedPassword = await encryptPassword(this.state.loginPassword)


      this.setState({ loader: true });
      axios
        .post(`${process.env.REACT_APP_BASE_URL}api/auth/local`, {
          identifier: this.state.loginEmail,
          password: encryptedPassword,
        })
        .then((res) => {
          // Handle authentication success

          if (res?.data?.user?.confirmed) {
            this.props.setloginDetails(res?.data)



            axios.get(process.env.REACT_APP_BASE_URL + `api/user-subscription-details?filters[userid][%24eq]=${res?.data?.user?.id}&active=true`,
              {
                headers: {
                  Authorization: `Bearer ${res?.data?.jwt}`
                }
              })
              .then((response) => {

                if (response?.data?.data.length > 0) {
                  let highestIdObject = { id: 0 };
                  response?.data?.data.forEach(item => {
                    if (item.id > highestIdObject?.id) {

                      highestIdObject = { ...item };
                    }
                  });


                  //Subscription_Commented_Code
                  //Uncomment this Subscription_Commented_Code
                  // if (highestIdObject?.attributes?.plan?.toUpperCase() === 'BRONZE') {
                  //   this.props?.setupgradePopup(true);
                  // }

                  const today = new Date();
                  const start = new Date(highestIdObject?.attributes?.subscription_date);
                  const end = new Date(highestIdObject?.attributes?.expiry);

                  if (!(today >= start && today <= end)) {
                    this.elseSubscriptionBlock(res)
                  }

                  this.props?.setloginSubscriptionDetail(response?.data?.data)
                } else {
                  this.elseSubscriptionBlock(res)
                }
              })
              .catch((err) => {
                console.error(" error:", err);
              })
              .finally(() => {
                this.props.history.push('/app/dashboard');
              });

          } else {
            this.setState({
              LoginInvalidMessage: 'The email or password is incorrect. Please verify your information or use ‘Forgot Password’ or ‘Create Account’'
            })

          }
        })
        .catch((err) => {
          // Handle authentication error
          console.error("Authentication error:", err);
          if (err?.response?.status) {
            this.setState({
              LoginInvalidMessage: 'Invalid email or password.'
            })
          } else {
            this.setState({
              LoginInvalidMessage: 'Something went wrong.Please try after sometime.'
            })
          }
        })
        .finally(() => {
          this.setState({ loader: false })
        });
    } catch (error) {
      console.error('Failed to encrypt password:', error);
    }
  }

  elseSubscriptionBlock = (res) => {
    var date = new Date();
    const expiredate = new Date();
    expiredate.setFullYear(date.getFullYear() + 1);

    axiosInstance
      .post(`${process.env.REACT_APP_BASE_URL}api/user-subscription-details`, {
        "data": {
          "userid": res?.data?.user?.id,
          "plan": "Bronze",
          "frequency": "yearly",
          "amount": "Free",
          "subscription_date": date.toISOString() ?? '',
          "active": true,
          "expiry": expiredate.toISOString() ?? '',
          "currency": 'usd',
          "subscription_id": null,
          "session_id": 'BY_DEFAULT_SESSION_ENTERED',
          "is_cancelled": false,
          "transaction_id": `uf_${Math.floor(1000 + Math.random() * 9000)}`,
        }
      })
      .then((response) => {


        axiosInstance
          .get(process.env.REACT_APP_BASE_URL + `api/user-subscription-details?filters[userid][%24eq]=${res?.data?.user?.id}&active=true`)
          .then((res) => {

            let highestIdObject = { id: 0 };
            response?.data?.data.forEach(item => {
              if (item.id > highestIdObject?.id) {
                highestIdObject = { ...item };
              }
            });


            //Subscription_Commented_Code
            //Uncomment this Subscription_Commented_Code
            // if (highestIdObject?.attributes?.plan?.toUpperCase() === 'BRONZE') {
            //   this.props?.setupgradePopup(true);
            // }
            this.props?.setloginSubscriptionDetail(response?.data?.data)

          })
          .catch((err) => {
            console.error(" error:", err);
          })
          .finally(() => {
          });
      })
      .catch((err) => {
        console.error("Authentication error:", err);
      })

  }
  handleEmailChange = (e) => {
    this.setState({ loginEmail: e.target.value });
  };

  handlePasswordChange = (e) => {
    this.setState({ loginPassword: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex?.test(this.state?.loginEmail)) {
      this.setState({ isValidEmail: false, isValidPassword: true });
    } else if (this.state.loginPassword?.length < 8) {
      this.setState({ isValidPassword: false, isValidEmail: true });
    } else {
      this.setState({ isValidEmail: true, isValidPassword: true });
      axios.get(process.env.REACT_APP_BASE_URL + `api/users?filters[email][$eq]l=${this.state?.loginEmail}`)
        .then((res) => {
          if (res?.data?.length > 0) {
            this.setState({ LoginInvalidMessage: '' });
            this.authenticationLogin();
          } else {
            this.setState({ LoginInvalidMessage: 'This email address is not registered. Please register before attempting to login.' })
          }
        })
        .catch((err) => {
          console.error(" error:", err);
          this.setState({ LoginInvalidMessage: 'Something went wrong.Please try after sometime.' });
        })
        .finally(() => {
          this.setState({ loader: false });

        });
    }

  };

  render() {
    return (
      <>
        <h4 className="mb-0">
          <div className="login-text">Login</div>
        </h4>
        <div className="my-2 login-subtext">
          Or{" "}
          <Link className='text-primary-link'
            to={{ pathname: '/auth/register' }}>
            create an account
          </Link>
        </div>
        <div>
          <div>
            <FormGroup floating>
              <Input type="email" name="email" id="exampleEmail" placeholder="Email"
                value={this.state.loginEmail} onChange={this.handleEmailChange} invalid={!this.state.isValidEmail} />
              <Label for="exampleEmail">
                Email
              </Label>
              <FormFeedback>Please enter a valid email address.</FormFeedback>
            </FormGroup>
            <FormGroup floating>
              <Input type="password" name="password" id="examplePassword" placeholder="Password" minLength={8} maxLength={16}
                value={this.state.loginPassword} onChange={this.handlePasswordChange} invalid={!this.state.isValidPassword} />
              <Label for="examplePassword">
                Password
              </Label>
              <FormFeedback>Min length of password is 8.</FormFeedback>
            </FormGroup>
            <div className="forgot-pass-text">
              <div className="forgot-pass-link">

                <Link className='btn-lg btn btn-link forgot-pass-link-1 text-primary-link'
                  to={{ pathname: '/auth/forgotpassword' }}>
                  Forgot Password?
                </Link>
                {" "}
              </div>
            </div>
          </div>


          <div className="login-button w-100 mt-3">
            {this.state?.LoginInvalidMessage &&
              <div className="error-msg-display">
                {this.state.LoginInvalidMessage}
              </div>}
            <Button type="submit" size="lg" color="primary" className=" w-100" disabled={!(this.state?.loginEmail && this.state?.loginPassword)} onClick={this.handleSubmit}>
              Login
            </Button>
          </div>
        </div>
        {this.state.loader &&
          <div className="backdrop">
            <div className="loader">
              <Loader type="ball-pulse-rise" />
            </div>
          </div>
        }
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
