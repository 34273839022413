import React, { Fragment } from "react";
import { Route, useLocation } from "react-router-dom";
import { Col, Row } from "reactstrap";
import Login from "../../Pages/UserLogin/Login";
import Register from "../../Pages/UserLogin/Register";
import ForgotPassword from "../../Pages/UserLogin/ForgotPassword";
import OTP from "../../Pages/UserLogin/OTP";
import BasicDetails from "../../Pages/UserLogin/BasicDetails";
import PrimaryRole from "../../Pages/UserLogin/Register/PrimaryRole";
import Work from "../../Pages/UserLogin/Register/Work";
import LeftPanel from "../../Pages/UserLogin/LeftPanel";
import forgetPasswordConfirmation from "../../Pages/UserLogin/ForgotPassword/forgetPasswordConfirmation";
import resetPassword from "../../Pages/UserLogin/ForgotPassword/resetPassword";
import afterPayment from "../../Pages/UserLogin/Register/afterPayment";
import Subscription from "../../Pages/UserLogin/Register/subscription";



const PreAuthLayout = ({ match, props , history }) => {
    const location = useLocation();
    const isSubscriptionPath = location.pathname.includes('subscription');
    const additionalProps = {
        from: 'register',
        history : history,     
    };

    return (
        <Fragment>
            <Row className="h-100 g-0">
                <LeftPanel isSubscriptionPath={isSubscriptionPath}></LeftPanel>
                <Col lg={isSubscriptionPath ? "8" : "5"} md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
                    <Col lg={"7"} md="9" sm="10" className={`app-login-box`} style={{ width: isSubscriptionPath ? '80%' : '' }}>

                        <Route path={`${match.url}/login`} component={Login} />
                        <Route path={`${match.url}/register`} component={Register} />
                        <Route path={`${match.url}/forgotpassword`} component={ForgotPassword} />
                        <Route path={`${match.url}/forgotPasswordConfirmation`} component={forgetPasswordConfirmation} />
                        <Route path={`${match.url}/resetPassword/:id`} component={resetPassword} />
                        <Route path={`${match.url}/otpVerify`} component={OTP} />
                        <Route path={`${match.url}/basicDetails`} component={BasicDetails} />
                        <Route path={`${match.url}/primaryRole`} component={PrimaryRole} />
                        <Route path={`${match.url}/work`} component={Work} />
                        <Route path={`${match.url}/subscription/afterPayment`} component={afterPayment} />
                        <Route exact path={`${match.url}/subscription`} render={() => <Subscription {...additionalProps} />} />

                    </Col>
                </Col>
            </Row>
        </Fragment>
    )
};

export default PreAuthLayout;
