import React, { Component } from "react";
import { connect } from "react-redux";
import backlogo from "../../assets/images/svg/Back.svg";
import { Link } from "react-router-dom";
import { FormGroup, Button, Label, Input } from "reactstrap";
import LineGraph from "../../assets/images/png/LineGraph.png";
import Select from "react-select";
import { removeEmojis } from "../ReusableComponent/RemoveEmoji";

const mapStateToProps = (state) => {
  return {
    reduxData: state.counterSlice,
  };
};

class FurtherAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tcgaSelected: [],
      ccleSelected: [],
      selectedRadio: "",
      analysisData: ["hello"],
      showDetails: false,
      cancerTypeOptions: [
        { value: "NSCLC", label: "NSCLC" },
        { value: "BRCA", label: "BRCA" },
      ],
      centralityOptions: [
        { value: "1", label: "Select 1" },
        { value: "2", label: "Select 2" },
        { value: "3", label: "Select 3" },
      ],
      pathwayOptions: [
        { value: "1", label: "pathway 1" },
        { value: "2", label: "pathway 2" },
        { value: "3", label: "pathway 3" },
      ],
      topValue: "",
      selectedResultGeneRadioList: null,
      allResultsChecked: false,
    };
  }

  ccleCheckboxes = ["Proliferation", "Drug Sensitivity"];
  tcgaCheckboxes = ["Tumorigenesis", "Survival"];
  resultGeneRadioList = [
    "Tumorigenesis",
    "Survival",
    "Proliferation",
    "Drug Sensitivity",
    "Selected Pathway",
    "Centrality",
  ];

  // Add radio options
  radioOptions = ["All", "Not Conflict", "Any Significant"];

  // Handle radio button change
  handleRadioChange = (label, type) => {
    if (type === "tcga") {
      this.setState({ tcgaSelected: [label] });
    } else if (type === "ccle") {
      this.setState({ ccleSelected: [label] });
    } else {
      this.setState({ selectedRadio: label });
    }
  };

  handleCancerTypeChange = (selectedOption) => {
    this.setState({ selectedCancerType: selectedOption });
  };

  handleCentralityChange = (selectedOption) => {
    this.setState({ selectedCentrality: selectedOption });
  };

  handlePathwayChange = (selectedOption) => {
    this.setState({ selectedPathway: selectedOption });
  };

  topValueChange = (event) => {
    const filteredValue = removeEmojis(event.target.value);
    this.setState({ topValue: filteredValue });
  };

  handleResultGeneRadioClick = (label) => {
    this.setState({
      selectedResultGeneRadioList:
        label === this.state.selectedResultGeneRadioList ? null : label,
      allResultsChecked: false,
    });
  };

  handleAllResultsCheckboxChange = () => {
    this.setState((prevState) => ({
      allResultsChecked: !prevState.allResultsChecked,
      selectedResultGeneRadioList: prevState.allResultsChecked
        ? null
        : this.resultGeneRadioList[0], // Select the first result if "All Results" is checked
    }));
  };

  handleRun = () => {
    this.setState({ showDetails: true });
  };

  handleClear = () => {
    this.setState({ showDetails: false });
  };

  render() {
    return (
      <div className="row">
        <div className="col-lg-3 col-md-4 col-sm-12 mt-2">
          <div className="card card-cancerType">
            <div className="top-container-div">
              <Link
                className="btn-link-primary"
                to={{ pathname: "/app/discover/myProject" }}
              >
                <div className="d-inline-flex">
                  <img src={backlogo} alt="" />
                  <span
                    style={{ color: "#333333", fontWeight: 400 }}
                    className="ms-1"
                  >
                    Back
                  </span>
                </div>
              </Link>

              <div className="title-div fw-bold d-flex align-items-center mt-2">
                Analysis Selection
              </div>

              {this.state.showDetails ? (
                <div className="further-analysis-filter">
                  <div className="mt-3">
                    {this.resultGeneRadioList.map((label, index) => (
                      <div
                        className="result-gene-container mb-2"
                        key={index}
                        onClick={() => this.handleResultGeneRadioClick(label)}
                        style={{
                          backgroundColor:
                            this.state.selectedResultGeneRadioList === label
                              ? "#5460D1"
                              : "#EAEAEA",
                          color:
                            this.state.selectedResultGeneRadioList === label
                              ? "white"
                              : "inherit",
                        }}
                      >
                        {label}
                      </div>
                    ))}
                  </div>
                  {/* <div>
                    <FormGroup check>
                      <Input
                        type="checkbox"
                        id="all-results-checkbox"
                        checked={this.state.allResultsChecked}
                        onChange={this.handleAllResultsCheckboxChange}
                      />
                      <Label
                        for="all-results-checkbox"
                        check
                        className="checkbox-label ml-2"
                        style={{ color: "#333333" }}
                      >
                        All Results
                      </Label>
                    </FormGroup>
                  </div> */}
                </div>
              ) : (
                <div className="further-analysis-filter">
                  <div className="cancer-type mt-3">
                    <FormGroup>
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            height: "40px",
                            cursor: "pointer",
                          }),
                          option: (base) => ({
                            ...base,
                            cursor: "pointer",
                          }),
                        }}
                        value={this.state.selectedCancerType}
                        onChange={this.handleCancerTypeChange}
                        options={this.state.cancerTypeOptions}
                        placeholder="Select Cancer Type"
                      />
                    </FormGroup>
                  </div>
                  <hr style={{ width: "100%" }} />
                  <div className="further-analysis-filter-1">
                    {/* TCGA Checkboxes */}
                    <div className="sub-title">TCGA</div>
                    <div className="mt-2">
                      {this.tcgaCheckboxes.map((label, index) => (
                        <div className="radio-button-container" key={index}>
                          <FormGroup check>
                            <Input
                              type="radio"
                              id={`tcga-checkbox-${index}`}
                              onChange={() =>
                                this.handleRadioChange(label, "tcga")
                              }
                              checked={this.state.tcgaSelected.includes(label)}
                            />
                            <Label
                              for={`tcga-checkbox-${index}`}
                              check
                              className="checkbox-label ml-2"
                            >
                              {label}
                            </Label>
                          </FormGroup>
                        </div>
                      ))}
                    </div>

                    {/* CCLE Checkboxes */}
                    <div className="sub-title">CCLE</div>
                    <div className="mt-2">
                      {this.ccleCheckboxes.map((label, index) => (
                        <div className="radio-button-container" key={index}>
                          <FormGroup check>
                            <Input
                              type="radio"
                              id={`ccle-checkbox-${index}`}
                              onChange={() =>
                                this.handleRadioChange(label, "ccle")
                              }
                              checked={this.state.ccleSelected.includes(label)}
                            />
                            <Label
                              for={`ccle-checkbox-${index}`}
                              check
                              className="checkbox-label ml-2"
                            >
                              {label}
                            </Label>
                          </FormGroup>
                        </div>
                      ))}
                    </div>
                    <div className="mt-2">
                      <div className="row">
                        {this.radioOptions.map((label, index) => (
                          <div
                            className={index >= 2 ? "col-12" : "col-6"}
                            key={index}
                          >
                            <FormGroup check>
                              <Input
                                type="radio"
                                name="radioGroup"
                                id={`radio-${index}`}
                                value={label}
                                onChange={() => this.handleRadioChange(label)}
                                checked={this.state.selectedRadio === label}
                              />
                              <Label
                                for={`radio-${index}`}
                                check
                                className="checkbox-label ml-2 fw-bold"
                              >
                                {label}
                              </Label>
                            </FormGroup>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <hr style={{ width: "100%" }} />
                  <div>
                    <div>
                      <FormGroup>
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              height: "40px",
                              cursor: "pointer",
                            }),
                            option: (base) => ({
                              ...base,
                              cursor: "pointer",
                            }),
                          }}
                          value={this.state.selectedCentrality}
                          onChange={this.handleCentralityChange}
                          options={this.state.centralityOptions}
                          placeholder="Select Centrality"
                        />
                      </FormGroup>
                    </div>
                    <div>
                      <FormGroup>
                        <Input
                          id="exampleEmail"
                          name="text"
                          placeholder="Top %"
                          type="text"
                          style={{ height: "40px" }}
                          value={this.state.topValue}
                          minLength={3}
                          maxLength={50}
                          onChange={this.topValueChange}
                        />
                      </FormGroup>
                    </div>
                    <div>
                      <FormGroup>
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              height: "40px",
                              cursor: "pointer",
                            }),
                            option: (base) => ({
                              ...base,
                              cursor: "pointer",
                            }),
                          }}
                          value={this.state.selectedPathway}
                          options={this.state.pathwayOptions}
                          placeholder="Pathway"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* <div className="bottom-container-div"></div> */}
            <div className="drugGroup-button">
              <div className="d-flex gap-2">
                <Button
                  className="w-100"
                  color="light"
                  onClick={() => this.handleClear()}
                >
                  Clear
                </Button>
                <Button
                  className="w-100"
                  color="primary"
                  onClick={() => this.handleRun()}
                  //   disabled={this.checkApplyDisabled()}
                >
                  Run
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-9 col-md-8 col-sm-12 mt-2">
          <div className="card section-container">
            {this.state.showDetails ? (
              <div className="gene-data-container">
                <div className="d-flex justify-content-between">
                  <div className="title-div fw-bold">Drug Discovery</div>
                  <Button className="" color="light">
                    Recommend Gene List for CMap
                  </Button>
                </div>
                <div className="mt-4">
                  <a href="#" className="gene-link">
                    Gene A
                  </a>
                  <a href="#" className="gene-link">
                    Gene B
                  </a>
                  <a href="#" className="gene-link">
                    Gene C
                  </a>
                  <a href="#" className="gene-link">
                    Gene D
                  </a>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-3">
                  <img src={LineGraph} alt="" className="graph-image" />
                </div>
                <div className="further-analysis-button" >
                <div className="d-flex align-items-center me-2">
                    <FormGroup check>
                      <Input
                        type="checkbox"
                        id="all-results-checkbox"
                        checked={this.state.allResultsChecked}
                        onChange={this.handleAllResultsCheckboxChange}
                      />
                      <Label
                        for="all-results-checkbox"
                        check
                        className="checkbox-label ml-2"
                        style={{ color: "#333333" }}
                      >
                        All Results
                      </Label>
                    </FormGroup>
                  </div>
                  <Button
                    className="w-80"
                    color="primary"
                    //   onClick={() => this.drugListApifn(1, "sensitive")}
                    //   disabled={this.checkApplyDisabled()}
                  >
                    Save
                  </Button>
                </div>
              </div>
            ) : (
              <div
                className="text-center fw-bold d-flex justify-content-center align-items-center"
                style={{ height: "100%" }}
              >
                Kindly fill the specifications on the left to get Gene list
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(FurtherAnalysis);
