import React, { Component } from "react";
import backlogo from '../../assets/images/svg/Back.svg'
import Loader from "react-loaders";
import { connect } from 'react-redux';
import axiosInstance from "../configuration/Request";

const mapStateToProps = (state) => {
    
    return {
        reduxData: state.counterSlice
    };
};



class Guidlines extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            communityGuideline: {},
        };
    }

    componentDidMount() {
        this.setState({ loader: true });
        axiosInstance.get(process.env.REACT_APP_BASE_URL + `api/comm-guideline?populate=deep`)
            .then(res => {
                
                this.setState({ communityGuideline: res.data?.data })
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                this.setState({ loader: false });
            });

    };





    render() {

        const { loader, communityGuideline } = this.state;

        return (
            <>
                <div>
                    <div>
                        <a class="btn-link-primary" href="#/app/forum">
                            <div class="d-inline-flex">
                                <img src={backlogo} alt="" />
                                <span class="ms-1" style={{ 'color': 'rgb(51, 51, 51)', 'font-weight': '400' }}>
                                    Back
                                </span>
                            </div>
                        </a>
                    </div>
                    <div className="Guidelines_header">
                        Community Guidelines
                    </div>
                    <div className="Guidelines_subheader">
                        {communityGuideline?.attributes?.description}
                    </div>
                    {communityGuideline?.attributes?.guidelines?.length > 0 &&
                        communityGuideline?.attributes?.guidelines.map(list => {
                            return (
                                <div className="Guidelines_content">
                                    <div className="Guidelines_content_header">
                                        {list?.Header}
                                    </div>

                                    {list?.data?.length > 0 &&
                                        list.data.map(sublist => {
                                            return (
                                                <div>
                                                    {sublist?.value}
                                                </div>

                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>

                {loader &&
                    <div className="backdrop">
                        <div className="loader">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default connect(mapStateToProps)(Guidlines);
