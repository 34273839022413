import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Button, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import backlogo from '../../../assets/images/svg/Back.svg'
import { setForgetPasswordEmail } from "../../../reducers/counterSlice";
import { connect } from 'react-redux';
import Loader from "react-loaders";
import axiosInstance from "../../configuration/Request";
const mapStateToProps = (state) => {

  return {
    reduxData: state.counterSlice
  };
};

const mapDispatchToProps = {
  setForgetPasswordEmail
};

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      loader: false,
      isValidEmail: true,
    };
  }


  onProceedClick = () => {

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex?.test(this.state?.email)) {
      this.setState({ isValidEmail: false, errorMessage: '' });
    } else {
      this.setState({ loader: true, isValidEmail: true });
      axiosInstance.get(process.env.REACT_APP_BASE_URL + `api/users?filters[email][$eq]l=${this.state?.email}`)
        .then((res) => {
          if (res?.data?.length > 0) {
            this.setState({ errorMessage: '' });
            this.forgetPassEmailSent();
          } else {
            this.setState({ errorMessage: 'This email address is not registered. Please register before attempting to reset your password.' })
          }
        })
        .catch((err) => {
          console.error(" error:", err);
          this.setState({ errorMessage: 'Something went wrong.Please try after sometime.' });
        })
        .finally(() => {
          this.setState({ loader: false });

        });
    }
  }

  forgetPassEmailSent = () => {
    this.setState({ loader: true });
    axiosInstance
      .post(process.env.REACT_APP_BASE_URL + "api/resetPassword", {
        "data": {
          "email": this.state.email ?? ''
        }
      })
      .then((res) => {
        this.props.setForgetPasswordEmail(this.state?.email)
        this.props?.history.push('/auth/forgotPasswordConfirmation')
      })
      .catch((err) => {
        console.error(" error:", err);
        this.setState({ errorMessage: 'Something went wrong.Please try after sometime.' });
      })
      .finally(() => {
        this.setState({ loader: false });
      });
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };


  render() {
    return (
      <>
        <div>
          <Link className='btn-link-primary'
            to={{ pathname: '/auth/login' }}>
            <div className="d-inline-flex gap-2" >
              <img src={backlogo} alt="" />
              <span className="back-text">Back</span>
            </div>
          </Link>
          <h4 className="mb-0">
            <div className="login-text">Forgot Password?</div>
          </h4>
          <h6 className="mt-3">
            We'll send an email with a link to rest your password.
          </h6>
          <div style={{ maxWidth: '24rem' }}>
            <div>
              <FormGroup floating>
                <Input type="email" name="email" id="exampleEmail" placeholder="Email" invalid={!this.state.isValidEmail}
                  value={this.state.email} onChange={this.handleEmailChange} />
                <Label for="exampleEmail">
                  Email
                </Label>
                <FormFeedback>Please enter a valid email address.</FormFeedback>
              </FormGroup>
              <div className="login-button w-100 mt-3">
                {this.state.errorMessage &&
                  <div className="error-msg-display">
                    {this.state.errorMessage}
                  </div>
                }
                <Button size="lg" color="primary" className="w-100" onClick={this.onProceedClick} disabled={!this.state.email}>
                  Proceed
                </Button>

              </div>
            </div>
            <div className="remember-pass">
              <div className="remember-pass-text">
                Remember Your Password?
              </div>
              <div >
                <Link
                  to={{ pathname: '/auth/login' }}>
                  <button className="btn btn-outline-primary "  >Sign In</button>
                </Link>

              </div>
            </div>
          </div>
        </ div>
        {this.state.loader &&
          <div className="backdrop">
            <div className="loader">
              <Loader type="ball-pulse-rise" />
            </div>
          </div>
        }
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
