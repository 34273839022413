import React, { Component } from "react";
import { Button, } from "reactstrap";
import { connect } from 'react-redux';
import { setloginDetails, setloginSubscriptionDetail } from '../../../reducers/counterSlice';
import Loader from "react-loaders";
import SuccessAnimation from "../../ReusableComponent/SuccessAnimation";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import SOSTOSLogo from "../../../assets/images/svg/Sostoslogo.svg";
import SweetAlert from 'react-bootstrap-sweetalert';
import axiosInstance from "../../configuration/Request";
import { encryptPassword } from "../../../utils/passwordEncryption";

const mapStateToProps = (state) => {

  return {
    reduxData: state.counterSlice
  };
};
const mapDispatchToProps = {
  setloginDetails, setloginSubscriptionDetail
};

class AfterPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      roleList: [],
      activePlanDetails: {},
      paymentFailedPopup: false,
    };

  };

  componentDidMount = async () => {
    this.setState({ loader: true });



    axiosInstance
      .get(process.env.REACT_APP_BASE_URL + `api/users-permissions/roles`)
      .then((res) => {

        this.setState({ roleList: res?.data?.roles });
      })
      .catch((err) => {
        console.error(" error:", err);
      })


    if (this.props?.reduxData?.loginDetails?.user?.id) {
      this.afterLoginAPIcall(this.props?.reduxData?.loginDetails?.user?.id)
    }
    else {
      let encryptedPassword = '';
      try {
        this.setState({ loader: true });

        encryptedPassword = await encryptPassword(this.props?.reduxData?.basicDetails?.password)
      } catch (error) {
        console.error('Failed to encrypt password:', error);
      }
      axiosInstance
        .post(`${process.env.REACT_APP_BASE_URL}api/auth/local`, {
          identifier: this.props?.reduxData?.registrationEmail,
          password: encryptedPassword,
        })
        .then((res) => {

          if (res?.data?.user?.confirmed) {
            this.props.setloginDetails(res?.data);
            // after payment confiramtion call this API to update role
            this.afterLoginAPIcall(res?.data?.user?.id)
          }
        })
        .catch((err) => {
          // Handle authentication error
          console.error("Authentication error:", err);
          this.setState({ loader: false, paymentFailedPopup: true })

        })
    }
  }

  afterLoginAPIcall = (id) => {

    const role = this.state.roleList?.find(item => item?.name?.toUpperCase() === this.props?.reduxData?.subscriptionDetail?.title?.toUpperCase())
    const queryString = new URLSearchParams(this.props.location?.search);
    const sessionId = queryString.get("sessionId");


    axiosInstance.put(process.env.REACT_APP_BASE_URL + `api/custom-subscription-detail/${id}`)
      .then(responsecustom => {
        axiosInstance
          .get(process.env.REACT_APP_BASE_URL + `api/payments/${sessionId}/status`)
          .then((response) => {

            if (response?.data?.payment_status?.toUpperCase() === 'PAID') {
              var date = new Date(new Date(response?.data?.created * 1000)?.toISOString());
              const expiredate = new Date();
              if (this.props?.reduxData?.subscriptionDetail?.subscriptionPlanTenure) {
                expiredate.setFullYear(date.getFullYear() + 1);
              } else {
                expiredate.setDate(date.getDate() + 30);
              }



              axiosInstance
                .post(`${process.env.REACT_APP_BASE_URL}api/user-subscription-details`, {
                  "data": {
                    "userid": id,
                    "plan": this.props?.reduxData?.subscriptionDetail?.title,
                    "frequency": this.props?.reduxData?.subscriptionDetail?.subscriptionPlanTenure ? "yearly" : "monthly",
                    "amount": (response?.data?.amount_total / 100)?.toFixed(2),
                    "subscription_date": response?.data?.created ? new Date(response?.data?.created * 1000)?.toISOString() : '',
                    "active": true,
                    "expiry": response?.data?.created ? expiredate?.toISOString() : '',
                    "currency": response?.data?.currency,
                    "subscription_id": null,
                    "session_id": sessionId,
                    "is_cancelled": false,
                    "transaction_id": response?.data?.payment_intent,
                  }
                })
                .then((response) => {


                  axiosInstance
                    .get(process.env.REACT_APP_BASE_URL + `api/user-subscription-details?filters[userid][%24eq]=${id}&active=true`)
                    .then((res) => {

                      let highestIdObject = { id: 0 };

                      res?.data?.data.forEach(item => {
                        if (item.id > highestIdObject?.id) {
                          highestIdObject = { ...item };
                        }
                      });

                      this.setState({ activePlanDetails: highestIdObject })
                      this.props?.setloginSubscriptionDetail(res?.data?.data)
                    })
                    .catch((err) => {
                      console.error(" error:", err);
                    })
                    .finally(() => {
                    });
                })
                .catch((err) => {
                  console.error("Authentication error:", err);
                }).finally(() => {

                });
            }
            else {
              this.setState({ paymentFailedPopup: true })
            }
          })
          .catch((err) => {
            console.error(" error:", err);
            this.setState({ paymentFailedPopup: true })
          })
          .finally(() => {
          });
      })
      .catch(error => {
      }).finally(() => {
        this.setState({ loader: false })
      });



    axiosInstance
      .put(`${process.env.REACT_APP_BASE_URL}api/users/${id}`, {
        role: role?.id
      })
      .then((response) => {


      })
      .catch((err) => {
        // Handle authentication error
        console.error("Authentication error:", err);

      })
      .finally(() => {

      });





  }

  generatePDF = () => {
    this.setState({ loader: true });


    // Create a hidden div to render the invoice content
    const invoiceContent = `
          <div style="font-family: Arial, sans-serif; width: 210mm; height: 297mm; padding: 20mm; box-sizing: border-box; border: 1px solid #ccc;">
            <div style="text-align: left;">
            <img src=${SOSTOSLogo} alt="" style="width: 120px"/>
            </div>
            <div style="text-align: left; margin-bottom: 20mm;">
              <h1 style="margin: 0;">SOSTOS</h1>
            </div>
            <div style="margin-bottom: 20mm;">
              <h2 style="margin: 0;">Invoice</h2>
              <p style="margin: 5mm 0 0 0;"><strong>Transaction id:</strong> ${this.state?.activePlanDetails?.attributes?.transaction_id}</p>
            </div>
            <div style="margin-bottom: 20mm;">
              <p style="margin: 0;"><strong>${this.props?.reduxData?.loginDetails?.user?.username}</strong></p>
              <p style="margin: 5mm 0 0 0;">${this.props?.reduxData?.loginDetails?.user?.email}</p>
            </div>
            <table style="width: 100%; border-collapse: collapse; margin-bottom: 20mm;">
              <thead>
                <tr>
                  <th style="border-bottom: 1px solid #ccc; text-align: left; padding-bottom: 5mm;">Item</th>
                  <th style="border-bottom: 1px solid #ccc; text-align: left; padding-bottom: 5mm;">Date</th>
                  <th style="border-bottom: 1px solid #ccc; text-align: left; padding-bottom: 5mm;">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="padding-top: 5mm;">${this.state?.activePlanDetails?.attributes?.plan} ${this.state?.activePlanDetails?.attributes?.frequency?.toUpperCase() === 'YEARLY' ? 'Annual' : "Monthly"}</td>
                  <td style="padding-top: 5mm;">${this.fetchDate(this.state?.activePlanDetails)}</td>
                  <td style="padding-top: 5mm;">$${this.state?.activePlanDetails?.attributes?.amount}</td>
                </tr>
              </tbody>
            </table>
            <div style="margin-top: 20mm;">
              <p style="margin: 0;">Questions about your order contact info@sostosllc.com</p>
            </div>
          </div>
        `;

    const hiddenDiv = document.createElement('div');
    hiddenDiv.innerHTML = invoiceContent;
    hiddenDiv.style.position = 'absolute';
    hiddenDiv.style.left = '-9999px';
    document.body.appendChild(hiddenDiv);

    html2canvas(hiddenDiv).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save("invoice.pdf");
      document.body.removeChild(hiddenDiv); // Clean up the hidden div
    });
    this.setState({ loader: false });
  }

  fetchDate = (activePlanDetails) => {
    const utcDate = new Date(activePlanDetails?.attributes?.subscription_date);

    const day = utcDate.getUTCDate().toString().padStart(2, '0');
    const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = utcDate.getUTCFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }

  onGoToDashboardClick = () => {

    this.props.history.push('/app/cancerType');

  }

  render() {

    return (
      <>
        <div>
          {!this.state?.paymentFailedPopup && <div lg="11" md="9" sm="10" className="box-container">
            <div className="header-container-subscription">
              <SuccessAnimation />
              <div className="Sub-title-subscription mt-3">Payment is completed successful.</div>
              <div className="Sub-title-subscription-subheader">You may now go ahead and explore SOSTOS.</div>

              <div className="d-flex gap-2" style={{ width: '50%' }}>
                <Button className="w-100" color="light" onClick={this.generatePDF} >
                  Download invoice
                </Button>
                <Button className="w-100" color="primary" onClick={this.onGoToDashboardClick} >
                  Go to dashboard
                </Button>
              </div>
            </div>
          </div>
          }
        </div >
        {this.state?.loader &&
          <div className="backdrop">
            <div className="loader">
              <Loader type="ball-pulse-rise" />
            </div>
          </div>
        }
        <SweetAlert title="Payment Failed!" show={this.state.paymentFailedPopup}
          customClass="custom-sweetalert"
          type='danger'
          confirmBtnText='Go To Subscription Page'
          onConfirm={() => { this.setState({ paymentFailedPopup: false }); this.props.history.push('/auth/subscription') }}>
          Your payment was not successful. Please try again.
        </SweetAlert>

      </>

    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(AfterPayment);