import React, { Component } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ErrorPopup from "../Components/ErrorPopup";
import SweetAlert from "react-bootstrap-sweetalert";

const mapStateToProps = (state) => {
  return {
    reduxData: state.counterSlice,
  };
};

class DiscoverDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      subscriptionLevel: "",
      openErrorPopup: false,
    };
  }

  componentDidMount() {
    let highestIdObject = { id: 0 };

    this.props?.reduxData?.loginSubscriptionDetail?.forEach((item) => {
      if (item.id > highestIdObject?.id) {
        highestIdObject = { ...item };
      }
    });
    this.setState({
      subscriptionLevel: highestIdObject?.attributes?.plan?.toLowerCase(),
    });
  }

  handleMouseEnter = () => {
    this.setState({ dropdownOpen: true });
  };

  handleMouseLeave = () => {
    this.setState({ dropdownOpen: false });
  };

  closeDropdowns = () => {
    this.setState({ dropdownOpen: false });
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
      nestedDropdownOpen: false,
    }));
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.closeDropdowns();
    }
  }

  handleItemClick = (path) => {
    if (this.state.subscriptionLevel === "gold") {
      if (path.startsWith("http")) {
        window.open(path, "_blank", "noopener noreferrer");
      } else {
        this.props.history.push(path);
      }
    } else {
      this.setState({ openErrorPopup: true });
    }
  };

  closePopup = () => {
    this.setState({ openErrorPopup: false });
  };

  render() {
    const { dropdownOpen } = this.state;
    const { location } = this.props;

    const isActive = location.pathname.includes("discover");

    const dropdownItems = [
      { path: "/app/discover/myProject", label: "Network Analysis" },
      { path: "https://clue.io/", label: "CMap" },
      { path: "https://mergeomics.research.idre.ucla.edu/runpharmomics.php", label: "PharmOmics" },
      { path: "/app/discover/discover&Repurposing", label: "Drug Discovery" },
    ];

    return (
      <Dropdown
        isOpen={dropdownOpen}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        toggle={this.toggleDropdown}
      >
        <DropdownToggle tag="div" aria-expanded={dropdownOpen}>
          {/* <Link className={`btn-link-primary ${isActive ? "link-active" : "link-inactive"}`} to="/app/discover/myProject">
            <span style={{ fontSize: "1rem" }}>Multi-Omics Network Analysis</span>
          </Link> */}
           <div
    className={`btn-link-primary ${isActive ? "link-active" : "link-inactive"}`}
    style={{cursor:"pointer"}}
    onClick={() => {
      if (this.state.subscriptionLevel === "gold") {
        this.props.history.push("/app/discover/myProject");
      } else {
        this.setState({ openErrorPopup: true });
      }
    }}
  >
    <span style={{ fontSize: "1rem" }}>Multi-Omics Network Analysis</span>
  </div>
        </DropdownToggle>
        <DropdownMenu>
          {dropdownItems.map((item, index) => (
            <DropdownItem key={index} className="custom-dropdown-item" onClick={() => this.handleItemClick(item.path)}>
              {item.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
        <SweetAlert
          custom
          show={this.state.openErrorPopup}
          customButtons={<React.Fragment></React.Fragment>}
          style={{ width: "auto", padding: "1rem" }}
        >
          <ErrorPopup
            closeAlert={this.closePopup}
            title="Access to this feature is exclusive to Gold members. Please update your subscription to gain access."
            buttonTitle="Ok"
          ></ErrorPopup>
        </SweetAlert>
      </Dropdown>
    );
  }
}

export default connect(mapStateToProps)(withRouter(DiscoverDropdown));



// import React, { Component } from "react";
// import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
// import { Link, withRouter } from "react-router-dom";

// class DiscoverDropdown extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       dropdownOpen: false,
//     };
//   }

//   handleMouseEnter = () => {
//     this.setState({ dropdownOpen: true });
//   };

//   handleMouseLeave = () => {
//     this.setState({ dropdownOpen: false });
//   };

//   closeDropdowns = () => {
//     this.setState({ dropdownOpen: false });
//   };

//     toggleDropdown = () => {
//     this.setState((prevState) => ({
//       dropdownOpen: !prevState.dropdownOpen,
//       nestedDropdownOpen: false,
//     }));
//   };

//   componentDidUpdate(prevProps) {
//     if (this.props.location !== prevProps.location) {
//       this.closeDropdowns();
//     }
//   }

//   render() {
//     const { dropdownOpen } = this.state;
//     const { location } = this.props;

//     const isActive = location.pathname.includes("discover");

//     const dropdownItems = [
//       { path: "/app/discover/myProject", label: "Network Analysis" },
//       { path: "https://clue.io/", label: "CMap" },
//       { path: "https://mergeomics.research.idre.ucla.edu/runpharmomics.php", label: "PharmOmics" },
//       { path: "/app/discover/discover&Repurposing", label: "Drug Discovery" },
//     ];

//     return (
//       <Dropdown
//         isOpen={dropdownOpen}
//         onMouseEnter={this.handleMouseEnter}
//         onMouseLeave={this.handleMouseLeave}
//         toggle={this.toggleDropdown}
//       >
//         <DropdownToggle tag="div" aria-expanded={dropdownOpen}>
//           <Link className={`btn-link-primary ${isActive ? "link-active" : "link-inactive"}`} to="/app/discover/myProject">
//             <span style={{ fontSize: "1rem" }}>Multi-Omics Network Analysis</span>
//           </Link>
//         </DropdownToggle>
//         <DropdownMenu>
//           {dropdownItems.map((item, index) => (
//             <DropdownItem key={index} className="custom-dropdown-item">
//             {item.path.startsWith("http") ? (
//               <a href={item.path} className="custom-dropdown-item" target="_blank" rel="noopener noreferrer" onClick={this.closeDropdowns}>
//                 {item.label}
//               </a>
//             ) : (
//               <Link to={item.path} className="custom-dropdown-item" onClick={this.closeDropdowns}>
//                 {item.label}
//               </Link>
//             )}
//           </DropdownItem>
//           ))}
//         </DropdownMenu>
//       </Dropdown>
//     );
//   }
// }

// export default withRouter(DiscoverDropdown);


