import React, { useState, useRef, useEffect } from "react";
import {  showErrorToast } from "../Components/Toaster";
const AutoSuggestSearch = ({ suggestions,disabled,onTagChange,onSuggestionsUpdate }) => {
  const [query, setQuery] = useState("");
  const [tags, setTags] = useState([]);
  const [results, setResults] = useState([]);
  const searchContainerRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (results.length > 0) {
      onSuggestionsUpdate();
    }
  }, [results]);

   useEffect(() => {
      if (onTagChange) {
        onTagChange(tags);
      }
    }, [tags]);

    useEffect(() => {
      if (disabled) {
        setTags([]);
        if (onTagChange) {
          onTagChange([]);
        }
      }
    }, [disabled]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    if (!value) {
      setResults([]);
      return;
    }
    const filteredResults = suggestions.filter((item) =>
      item.toLowerCase().startsWith(value.toLowerCase())
    );
    setResults(filteredResults);
  };

  const handleSuggestionClick = (suggestion) => {
    if (!tags.includes(suggestion)) {
      setTags([...tags, suggestion]);
    }
    setQuery("");
    setResults([]); 
  };

  const handleTagRemove = (tag) => {
    setTags(tags.filter((t) => t !== tag));
  };

  const handleClickOutside = (event) => {
    if (
      searchContainerRef.current &&
      !searchContainerRef.current.contains(event.target)
    ) {
      setResults([]); 
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && query) {
      if (!suggestions.includes(query)) {
         showErrorToast("Gene not found in the list.");
        return;
      }
      if (!tags.includes(query)) {
        setTags([...tags, query]);
      }
      setQuery("");
      setResults([]);
    }
  };


  return (
    <div>
      <div className="tags-container">
        {tags.map((tag, index) => (
          <div key={index} className="tag">
            {tag}
            <span className="tag-close" onClick={() => handleTagRemove(tag)}>
              ✕
            </span>
          </div>
        ))}
      </div>

      <div className="search-container" ref={searchContainerRef}>
        <div className="search-box-with-suggestions">
          <input
            type="text"
            placeholder="Focused Gene..."
            value={query}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            className="search-input"
            disabled={disabled}
            style={{  backgroundColor: disabled ? "#E9ECEF" : "",}}
          />
          {results.length > 0 && (
            <div className="suggestions-box">
              {results.map((suggestion, index) => (
                <div
                  key={index}
                  className="suggestion"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AutoSuggestSearch;

// import React, { useState, useRef, useEffect } from "react";
// import { showErrorToast } from "../Components/Toaster";

// const AutoSuggestSearch = ({ suggestions, disabled, onTagChange }) => {
//   const [query, setQuery] = useState("");
//   const [tags, setTags] = useState([]);
//   const [results, setResults] = useState([]);
//   const [dropdownPosition, setDropdownPosition] = useState("below"); // "below" or "above"
//   const searchContainerRef = useRef(null);
//   const suggestionsBoxRef = useRef(null);

//   useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   useEffect(() => {
//     if (onTagChange) {
//       onTagChange(tags);
//     }
//   }, [tags]);

//   useEffect(() => {
//     if (disabled) {
//       setTags([]);
//       setQuery("")
//       if (onTagChange) {
//         onTagChange([]);
//       }
//     }
//   }, [disabled]);

//   const handleInputChange = (e) => {
//     const value = e.target.value;
//     setQuery(value);
//     if (!value) {
//       setResults([]);
//       return;
//     }
//     const filteredResults = suggestions.filter((item) =>
//       item.toLowerCase().startsWith(value.toLowerCase())
//     );
//     setResults(filteredResults);
//   };

//   const handleSuggestionClick = (suggestion) => {
//     if (!tags.includes(suggestion)) {
//       setTags([...tags, suggestion]);
//     }
//     setQuery("");
//     setResults([]);
//   };

//   const handleTagRemove = (tag) => {
//     setTags(tags.filter((t) => t !== tag));
//   };

//   const handleClickOutside = (event) => {
//     if (
//       searchContainerRef.current &&
//       !searchContainerRef.current.contains(event.target)
//     ) {
//       setResults([]);
//     }
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === "Enter" && query) {
//       if (!suggestions.includes(query)) {
//         showErrorToast("Gene not found in the list.");
//         return;
//       }
//       if (!tags.includes(query)) {
//         setTags([...tags, query]);
//       }
//       setQuery("");
//       setResults([]);
//     }
//   };

//   // Determine if dropdown should open above or below the input
//   useEffect(() => {
//     if (results.length > 0 && searchContainerRef.current) {
//       const rect = searchContainerRef.current.getBoundingClientRect();
//       const spaceBelow = window.innerHeight - rect.bottom;
//       const spaceAbove = rect.top;

//       if (spaceBelow < 200 && spaceAbove > 200) {
//         setDropdownPosition("above");
//       } else {
//         setDropdownPosition("below");
//       }
//     }
//   }, [results]);

//   return (
//     <div>
//       <div className="tags-container">
//         {tags.map((tag, index) => (
//           <div key={index} className="tag">
//             {tag}
//             <span className="tag-close" onClick={() => handleTagRemove(tag)}>
//               ✕
//             </span>
//           </div>
//         ))}
//       </div>

//       <div className="search-container" ref={searchContainerRef} style={{ position: "relative" }}>
//         <div className="search-box-with-suggestions">
//           <input
//             type="text"
//             placeholder="Focused Gene..."
//             value={query}
//             onChange={handleInputChange}
//             onKeyDown={handleKeyDown}
//             className="search-input"
//             disabled={disabled}
//             style={{ backgroundColor: disabled ? "#E9ECEF" : "" }}
//           />
//           {results.length > 0 && (
//             <div
//               ref={suggestionsBoxRef}
//               className="suggestions-box"
//               style={{
//                 position: "absolute",
//                 top: dropdownPosition === "below" ? "100%" : "auto",
//                 bottom: dropdownPosition === "above" ? "100%" : "auto",
//                 left: 0,
//                 right: 0,
//                 zIndex: 1050,
//               }}
//             >
//               {results.map((suggestion, index) => (
//                 <div
//                   key={index}
//                   className="suggestion"
//                   onClick={() => handleSuggestionClick(suggestion)}
//                 >
//                   {suggestion}
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AutoSuggestSearch;
