import React, { Component } from "react";
import CytoscapeComponent from "react-cytoscapejs";
import generateGenesAndEdges from "./../../utils/geneGenerate";
import { Button } from "reactstrap";

class CytoscapeGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialElements: generateGenesAndEdges(this.props.nodeDetails),
      elements: generateGenesAndEdges(this.props.nodeDetails),
      layout: { name: "random", fit: true, padding: 0 },
    };
    this.handleGeneClick = this.handleGeneClick.bind(this);
    this.handleLayoutComplete = this.handleLayoutComplete.bind(this);
    this.resetGraph = this.resetGraph.bind(this);
    this.cyRef = React.createRef();
  }

  handleGeneClick(event) {
    const clickedNode = event.target;
    const clickedNodeData = clickedNode.data();
    const connectedEdges = clickedNode.connectedEdges();
    const connectedEdgesData = connectedEdges.map((edge) => edge.data());
    if (this.props.onGeneClick) {
      this.props.onGeneClick({
        clickedNodeData,
        connectedEdgesData,
      });
    }
  }

  handleLayoutComplete(cy) {
    const newElements = cy.nodes().map((node) => {
      const { x, y } = node.position();
      return {
        data: node.data(),
        position: { x, y }, 
      };
    });

    this.setState({
      elements: newElements,
      layout: { name: "preset" }, 
    });
  }

  handleZoomIn = () => {
    const cy = this.cyRef.current;
    const currentZoom = cy.zoom();
    cy.zoom({
      level: currentZoom * 1.2, 
      renderedPosition: { x: cy.width() / 2, y: cy.height() / 2 },
    });
  };

  handleZoomOut = () => {
    const cy = this.cyRef.current;
    const currentZoom = cy.zoom();
    cy.zoom({
      level: currentZoom * 0.8,
      renderedPosition: { x: cy.width() / 2, y: cy.height() / 2 },
    });
  };

  resetGraph() {
    if (this.props.onResetClick) {
      this.props.onResetClick();
    }
  }

  downloadGraphAsImage = () => {
    const cy = this.cyRef.current;

    setTimeout(() => {
      const pngData = cy.png({
        full: true,
        scale: 1.2,
        bg: "white",
      });

      const link = document.createElement("a");
      link.href = pngData;
      link.download = "cytoscape-graph.jpg";

      link.click();
    }, 500);
  };

  render() {
    const stylesheet = [
      {
        selector: "node",
        style: {
          label: "data(label)",
          "text-valign": "center",
          "text-halign": "center",
           "background-color": "data(colorType)",
          shape: "data(shapeType)",
          "text-outline-color": "#fff",
          "text-outline-width": "2px",
          "text-wrap": "wrap",
          "font-size": "10px",
          "text-max-width": "50px",
          cursor: "pointer",

          width: (ele) => {
            const label = ele.data("label") || "";
            const labelLength = label.length;
            return Math.max(30, labelLength * 7);
          },
          height: (ele) => {
            const label = ele.data("label") || "";
            const labelLength = label.length;
            return Math.max(30, labelLength * 4);
          },
        },
      },
      {
        selector: "edge",
        style: {
           width: 2,
           "line-color": "#9dbaea",
           "target-arrow-color": "#9dbaea",
          "target-arrow-shape": "triangle",
        },
      },
    ];

    return (
      <div>
        <div className="d-flex">
          <div className="cytoscape-container">
            <CytoscapeComponent
              elements={this.state.elements}
              style={{ width: "100%", height: "97%" }}
              layout={this.state.layout}
              stylesheet={stylesheet}
              cy={(cy) => {
                this.cyRef.current = cy;
                cy.on("tap", "node", this.handleGeneClick);
                cy.once("layoutstop", () => this.handleLayoutComplete(cy));
              }}
              zoomingEnabled={true}
              panningEnabled={true}
              autounselectify={false}
              boxSelectionEnabled={true}
              userPanningEnabled={true}
            />
          </div>
          <div className="zoom-buttons-container">
            <button className="zoom-button" onClick={this.handleZoomIn}>
              +
            </button>
            <button className="zoom-button" onClick={this.handleZoomOut}>
              -
            </button>
          </div>
        </div>
        <div className="view-network-button-container">
          <Button
            className="view-reset-buttons"
            color="secondary"
            onClick={this.resetGraph}
          >
            Reset Network
          </Button>
          <Button
            className="view-download-buttons w-80"
            color="primary"
            onClick={this.downloadGraphAsImage}
          >
            Download Results
          </Button>
        </div>
   
      </div>
    );
  }
}

export default CytoscapeGraph;
