import React from "react";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import ErrorPopup from "../Components/ErrorPopup";

const SubscriptionCheck = (WrappedComponent) => {
  class AuthCheck extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        openErrorPopup: false,
        subscriptionLevel:"",
      };
    }

    componentDidMount() {
        let highestIdObject = { id: 0 };
        this.props?.reduxData?.loginSubscriptionDetail?.forEach(item => {
            if (item.id > highestIdObject?.id) {
                highestIdObject = { ...item };
            }
        });
        this.setState({subscriptionLevel:highestIdObject?.attributes?.plan?.toLowerCase()})
      if (highestIdObject?.attributes?.plan?.toLowerCase() !== "gold") {
        this.setState({ openErrorPopup: true });
      }
    }

    closePopup = () => {
      this.setState({ openErrorPopup: false });
      this.props.history.push("/app/dashboard"); 
    };

    render() {
      if (this.state.subscriptionLevel === "gold") {
        return <WrappedComponent {...this.props} />;
      }
      return (
        <SweetAlert
          custom
          show={this.state.openErrorPopup}
          customButtons={<React.Fragment></React.Fragment>}
          style={{ width: "auto", padding: "1rem" }}
        >
          <ErrorPopup
            closeAlert={this.closePopup}
            title="Access to this feature is exclusive to Gold members. Please update your subscription to gain access."
            buttonTitle="Ok"
          />
        </SweetAlert>
      );
    }
  }

  const mapStateToProps = (state) => ({
    reduxData: state.counterSlice,
  });

  return connect(mapStateToProps)(AuthCheck);
};

export default SubscriptionCheck;
