import React, { useState } from "react";
import { Tooltip } from "reactstrap";

const TooltipComponent = ({ id, tooltipText, children }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <div style={{ display: "inline-block", marginRight: "1rem" }}>
      <div id={id}>{children}</div>
      <Tooltip isOpen={tooltipOpen} target={id} toggle={toggleTooltip}>
        {tooltipText}
      </Tooltip>
    </div>
  );
};

export default TooltipComponent;
