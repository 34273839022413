import React, { Component } from "react";
import { connect } from "react-redux";
import { FormGroup, Button, Label, Input } from "reactstrap";
import Select from "react-select";
import { removeEmojis } from "../ReusableComponent/RemoveEmoji";
import LineGraph from "../../assets/images/png/LineGraph.png";

const mapStateToProps = (state) => {
  return {
    reduxData: state.counterSlice,
  };
};

class DrugDiscover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drugName:"",
      selectedRadio: "",
      drugData: [],
      searchText: "",
      selectedResult: null,
      resultOptions: [],
      removeOutliner: false,
      showDetails:false,
      cancerTypeOptions: [
        { value: "NSCLC", label: "NSCLC" },
        { value: "BRCA", label: "BRCA" }
      ]
    };
  }
  radioOptions = ["NSCLC", "BRCA"];

  handleRadioChange = (value) => {
    this.setState({ selectedRadio: value });
  };

  handleCancerTypeChange = (selectedOption) => {
    this.setState({ 
      selectedCancerType: selectedOption 
    });
  };

  drugNameChange = (event) => {
    const filteredValue = removeEmojis(event.target.value);
    this.setState({ drugName: filteredValue });
  };

  handleChange = (event) => {
    this.setState({ selectedResult: event });
  };
  handleCheckboxChange = (e) => {
    this.setState({
      removeOutliner: e.target.checked,
    });
  };

  handleSubmit = () => {
    this.setState({ showDetails: true });
  }

  handleCancel = () => {
    this.setState({ showDetails: false });
  };

  render() {
    const { drugName, selectedResult, removeOutliner } = this.state;
    return (
      <div className="row">
        <div className="col-lg-3 col-md-4 col-sm-12 mt-2">
          <div className="card card-cancerType">
            <div className="drug-discovry-filter">
              <div className="drug-discovery-container">
                <div className="title-div fw-bold d-flex align-items-center mb-3">
                  Drug Discovery
                </div>
                <div>
                  <FormGroup>
                    <Input
                      id="exampleEmail"
                      name="text"
                      placeholder="Enter Drug Name"
                      type="text"
                      style={{ height: "40px" }}
                      value={drugName}
                      minLength={3}
                      maxLength={50}
                      onChange={this.drugNameChange}
                    />
                  </FormGroup>
                </div>
              </div>
              <hr style={{ width: "100%", margin: "0.5rem 0" }} />
              <div className="cancer-type-container">
                <Label
                  style={{ fontSize: "0.75rem", marginBottom: "0.75rem" }}
                >
                  Cancer Type
                </Label>
                <FormGroup>
                  <Select
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        height: "40px",
                        cursor: "pointer",
                      }),
                      option: (base) => ({
                        ...base,
                        cursor: "pointer",
                      }),
                    }}
                    value={this.state.selectedCancerType}
                    onChange={this.handleCancerTypeChange}
                    options={this.state.cancerTypeOptions}
                    placeholder="Select Cancer Type"
                  />
                </FormGroup>
              </div>
              <hr style={{ width: "100%", margin: "0.5rem 0" }} />
              <div className="drug-discovery-container">
                <FormGroup>
                  <Select
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        height: "40px",
                        cursor: "pointer",
                      }),
                      option: (base) => ({
                        ...base,
                        cursor: "pointer",
                      }),
                    }}
                    value={selectedResult}
                    onChange={this.handleChange}
                    options={this.state.resultOptions}
                    placeholder="In-vitro Results in CCLE"
                  />
                </FormGroup>
                <div className="pt-3">
                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      checked={removeOutliner}
                      onChange={this.handleCheckboxChange}
                    />
                    <Label check className="checkbox-label">
                      Remove Outliner
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </div>

            {/* <div className="bottom-container-div"></div> */}
            <div className="drugGroup-button">
              <div className="d-flex gap-2">
                <Button className="w-100" color="light"
                  onClick={() => this.handleCancel()}>
                  Cancel
                </Button>
                <Button
                  className="w-100"
                  color="primary"
                     onClick={() => this.handleSubmit()}
                  //   disabled={this.checkApplyDisabled()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-9 col-md-8 col-sm-12 mt-2">
          <div className="card section-container">
            {this.state.showDetails ? (
               <div className="discovery-left-container" >
                   <div className="d-flex justify-content-center align-items-center mt-3" >
                     <img src={LineGraph} alt="" className="discovery-image"  />
                   </div>
                   <div className="discovery-save-button d-flex justify-content-end align-items-center">
                      <Button
                        className="next-button-parameter me-2 "
                        color="primary"
                        // onClick={() => { this.props.history.push('/app/discover/networkTask') }}
                        // onClick={this.onSaveClick}
                         >
                        Save
                      </Button>
                   </div>
               </div>
            ) : (
              <div
                className="text-center fw-bold d-flex justify-content-center align-items-center"
                style={{ height: "100%" }}
              >
                Kindly fill the specifications on the left to get drug
                discovery/ repurposing results
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(DrugDiscover);
