import React, { Component } from "react";
import { connect } from "react-redux";
import backlogo from "../../assets/images/svg/Back.svg";
import { Link } from "react-router-dom";
import Loader from "react-loaders";
import CytoscapeGraph from "./CytoscapeGraph";
import axiosInstance from "../configuration/Request";
import { ToastContainer } from "react-toastify";
import { showErrorToast } from "../Components/Toaster";
import { MdRemoveRedEye } from "react-icons/md";
import { IoEyeOffSharp } from "react-icons/io5";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IoIosInformationCircle } from "react-icons/io";

const mapStateToProps = (state) => {
  return {
    reduxData: state.counterSlice,
  };
};

class ViewNetwork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGene: null,
      loader: false,
      nodeData: {},
      geneConnectionData: [],
      associationType: "",
      graphKey: Date.now(),
      p_value: "",
      precision: "",
      scope: "",
      showLegend: false,
    };
  }

  handleGeneSelection = (gene) => {
    this.setState({
      selectedGene: gene?.clickedNodeData,
      geneConnectionData: gene?.connectedEdgesData,
    });
  };

  componentDidMount() {
    // this.setState({
    //   nodeData: {
    //     nodes: this.props.reduxData.cytoscapeData.cytoscape.nodes,
    //     edges: this.props.reduxData.cytoscapeData.cytoscape.edges,
    //   },
    //   associationType: this.props?.reduxData?.cytoscapeData?.association_type,
    // });
    this.fetchCytoscapeDetails();
  }
  fetchCytoscapeDetails = () => {
    this.setState({ loader: true });
    axiosInstance
      .get(
        `${process.env.REACT_APP_BASE_URL}api/network-cytoscape?id=${this.props.reduxData.cytoscapeData.cytoscapeID}`
      )
      .then((res) => {
        this.setState({
          nodeData: {
            nodes: res?.data?.data?.cytoscape.nodes,
            edges: res?.data?.data?.cytoscape.edges,
          },
          associationType: res?.data?.data?.association_type
            ? res?.data.data?.association_type
            : "",
          p_value: res?.data?.data?.p_value ? res?.data.data?.p_value : "",
          scope: res?.data?.data?.scope ? res?.data.data?.scope : "",
          precision: res?.data?.data?.precision
            ? res?.data.data?.precision
            : "",
        });
      })

      .catch((err) => {
        if (err?.response?.data?.error?.message) {
          showErrorToast(err?.response?.data?.error?.message, false);
        } else {
          showErrorToast("Error while fetching network", false);
        }
      })
      .finally(() => {
        this.setState({
          loader: false,
        });
      });
  };

  handleGraphReset = () => {
    this.setState({ graphKey: Date.now() });
  };

  legendHandler = () => {
    this.setState((prevState) => ({ showLegend: !prevState.showLegend }));
  };

  render() {
    return (
      <div className="network-view-top">
        <ToastContainer />

        <div className="network-view-left mt-2">
          <div className="card card-cancerType">
            <div className="view-network-container">
              <Link
                className="btn-link-primary"
                to={{ pathname: "/app/discover/myProject" }}
              >
                <div className="d-inline-flex">
                  <img src={backlogo} alt="" />
                  <span
                    style={{ color: "#333333", fontWeight: 400 }}
                    className="ms-1"
                  >
                    Back to Networks List
                  </span>
                </div>
              </Link>
            </div>

            <div className="network-view-left-inside">
              <div className="network-view-top-container">
                <div className="title-div fw-bold d-flex align-items-center">
                  Parameters of this Network
                </div>
                <div className="pt-1">
                  {this.state?.p_value && (
                    <div className="mb-2">
                      Significances Level:
                      <span className="fw-bold">
                        &nbsp;{this.state?.p_value}
                      </span>
                    </div>
                  )}

                  {this.state?.precision && (
                    <div className="mb-2">
                      Precision Threshold:
                      <span className="fw-bold">
                        &nbsp;{this.state?.precision}
                      </span>
                    </div>
                  )}
                  {this.state?.scope && (
                    <div className="mb-2">
                      Scope Threshold:
                      <span className="fw-bold">&nbsp;{this.state?.scope}</span>
                    </div>
                  )}

                  <div>
                    Association Type:
                    <span className="fw-bold">
                      &nbsp;{this.state.associationType || "N/A"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="legend-container">
                <div>
                  <div
                    className="legend-top fw-bold"
                    onClick={this.legendHandler}
                  >
                    <span> Nodes Representation</span>
                    <span className=" ms-2" >
                      {" "}
                      <IoIosInformationCircleOutline size={18} />{" "}
                    </span>
                  </div>
                  {this.state.showLegend && (
                    <ul className="mt-2">
                      <li>
                        <div className="shape-outer">
                          <span className="legend-shape triangle"></span>
                        </div>{" "}
                        CNV
                      </li>
                      <li>
                        <div className="shape-outer">
                          <span className="legend-shape ellipse"></span>
                        </div>{" "}
                        mRNA
                      </li>
                      <li>
                        <div className="shape-outer">
                          <span className="legend-shape rectangle"></span>{" "}
                        </div>{" "}
                        Protein
                      </li>
                      <li>
                        <div className="shape-outer">
                          {" "}
                          <span className="legend-shape diamond"></span>
                        </div>{" "}
                        Mutation
                      </li>
                      <li>
                        <div className="shape-outer">
                          <span className="legend-shape hexagon"></span>
                        </div>{" "}
                        Fusion
                      </li>
                      <li>
                        <div className="shape-outer">
                          <span className="legend-shape octagon"></span>
                        </div>{" "}
                        Methylation
                      </li>
                    </ul>
                  )}
                </div>
              </div>
              {this.state?.selectedGene && (
                <div className="gene-details-container">
                  <div className="fw-bold">
                    {this.state?.selectedGene?.name}
                  </div>
                  <div className="table-container">
                    <table>
                      <thead>
                        <tr>
                          <th style={{ width: "20%" }}>Source</th>
                          <th style={{ width: "20%" }}>Target</th>
                          <th style={{ width: "60%" }}>Association Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.geneConnectionData.length > 0 ? (
                          this.state.geneConnectionData.map((row, index) => (
                            <tr key={index}>
                              <td>{row.source}</td>
                              <td>{row.target}</td>
                              <td>{row.associationType}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3">No data found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="network-view-right mt-2">
          <div
            className="card section-container-view"
            style={{ padding: "16px" }}
          >
            {this.state.nodeData?.nodes?.length > 0 ? (
              <CytoscapeGraph
                key={this.state.graphKey}
                nodeDetails={this.state.nodeData}
                onGeneClick={this.handleGeneSelection}
                onResetClick={this.handleGraphReset}
              />
            ) : (
              <div
                className="text-center fw-bold d-flex justify-content-center align-items-center"
                style={{ height: "100%" }}
              >
                Network not Found
              </div>
            )}
          </div>
        </div>
        {this.state.loader && (
          <div className="backdrop">
            <div className="loader">
              <Loader type="ball-pulse-rise" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(ViewNetwork);
