import React, { Component } from "react";
import {
    FormGroup,
    Input,
    Button,
} from "reactstrap";
import backlogo from '../../assets/images/png/backlogo.png';
import { Link } from 'react-router-dom';
import Loader from "react-loaders";
import { connect } from 'react-redux';
import { BiSolidLike } from "react-icons/bi";
import { AiOutlineLike } from "react-icons/ai";
import 'react-toastify/dist/ReactToastify.css';
import { toast, Bounce, Slide, ToastContainer } from "react-toastify";
import { MdDeleteOutline } from "react-icons/md";
import SweetAlert from 'react-bootstrap-sweetalert';
import axiosInstance from "../configuration/Request";

const mapStateToProps = (state) => {

    return {
        reduxData: state.counterSlice
    };
};


class ForumDescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            fetchedData: [],
            liked: false,
            likesCount: 11,
            commentAddedText: '',
            forumDeleted: false,
        };
    }

    componentDidMount() {

        this.descAPICall();
    };

    descAPICall = () => {
        const id = this.props?.match?.params?.id;
        this.setState({ loader: true })
        axiosInstance.get(process.env.REACT_APP_BASE_URL + `api/forums/${id}?populate=deep`)
            .then(res => {


                const data = res?.data?.data;
                const idToCheck = this.props?.reduxData?.loginDetails?.user?.id;
                const isLiked = res?.data?.data.attributes.liked_by.data.some(item => item.id === idToCheck);
                data.attributes.liked = isLiked;
                data.attributes.user_comments.data.forEach(comment => {
                    const isLiked = comment.attributes.comments_liked_by.data.some(user => user.id === idToCheck);
                    comment.attributes.liked = isLiked;
                });

                data.attributes.user_comments.data.sort((a, b) => new Date(b?.attributes?.date) - new Date(a?.attributes?.date));
                console.log(res?.data?.data , 'res?.data?.data')
                res.data.data.attributes.body = res?.data?.data?.attributes?.body?.replace(
                    /(<table[^>]*>.*?<\/table>)/gi,
                    '<div class="table-wrapper">$1</div>'
                )
                console.log(res?.data?.data , 'res?.data?.data')
                this.setState({ fetchedData: res?.data?.data })

            }).catch(err => {
                console.log(err)
            }).finally(() => {
                this.setState({ loader: false })

            });

    }


    toggleLikeForumLike = (liked) => {

        const ids = this.state.fetchedData?.attributes?.liked_by?.data?.map(item => item.id);


        if (!liked) {
            ids.push(this.props?.reduxData?.loginDetails?.user?.id)
        } else {
            ids.splice(ids.indexOf(this.props?.reduxData?.loginDetails?.user?.id), 1)
        }

        axiosInstance.put(process.env.REACT_APP_BASE_URL + `api/forums/${this.props?.match?.params?.id}`, {
            "data": {
                "liked_by": ids
            }
        })
            .then(response => {
                this.descAPICall();

            })
            .catch(error => {
            });


    };

    toggleLikeCommentLike = (id, attributes) => {

        const ids = attributes?.comments_liked_by?.data?.map(item => item.id);


        if (!attributes?.liked) {
            ids.push(this.props?.reduxData?.loginDetails?.user?.id)
        } else {
            ids.splice(ids.indexOf(this.props?.reduxData?.loginDetails?.user?.id), 1)
        }


        axiosInstance.put(process.env.REACT_APP_BASE_URL + `api/user-comments/${id}`,
            {
                "data": {
                    "forumid": Number(this.props?.match?.params?.id),
                    "comments_liked_by": ids
                }
            })
            .then(response => {
                this.descAPICall();

            })
            .catch(error => {
            });
    };


    getLikeCount = (count) => {
        return count > 1 ? count + ' Likes' : count + ' Like';
    };

    addCommentClick = () => {
        var date = new Date();
        const id = this.props?.match?.params?.id;

        axiosInstance
            .post(process.env.REACT_APP_BASE_URL + "api/user-comments", {
                "data": {
                    "comment": this.state.commentAddedText,
                    "date": date.toISOString() ?? '',
                    "userid": this.props?.reduxData?.loginDetails?.user?.id ?? 0,
                    "forumid": id
                }
            })
            .then((res) => {
                this.setState({ commentAddedText: '' })
                toast(
                    "Comment added successfully!",
                    {
                        transition: Bounce,
                        closeButton: true,
                        autoClose: 5000,
                        position: "top-right",
                        type: "success",
                    }
                );
                this.descAPICall();
            })
            .catch((err) => {
                console.error(" error:", err);
            })
            .finally(() => {

            });
    };

    handleCommentAddedChange = (event) => {
        this.setState({ commentAddedText: event.target.value })
    };

    getDays = (createdTime) => {
        const currentTime = new Date();
        const parsedTimestamp = new Date(createdTime);
        const differenceInMs = currentTime - parsedTimestamp;

        const hours = Math.floor(differenceInMs / (1000 * 60 * 60));
        const days = Math.floor(hours / 24);
        const minutes = Math.floor(differenceInMs / (1000 * 60));

        let difference;
        if (days > 0) {
            difference = `${days} day${days !== 1 ? 's' : ''}`;
        } else if (hours > 0) {
            difference = `${hours} hour${hours !== 1 ? 's' : ''}`;
        } else {
            difference = `${minutes} minute${minutes !== 1 ? 's' : ''}`;
        }

        return difference;
    };

    deleteComment = (id) => {
        this.setState({ loader: true });
        axiosInstance.delete(process.env.REACT_APP_BASE_URL + `api/user-comments/${id}`)
            .then(response => {
                this.descAPICall();
            })
            .catch(error => {
            }).finally(() => {
                toast(
                    "Comment deleted successfully!",
                    {
                        transition: Bounce,
                        closeButton: true,
                        autoClose: 5000,
                        position: "top-right",
                        type: "success",
                    }
                );
                this.setState({ loader: false });
            });
    };

    checkCommentDelete = (item) => {
        return this.props?.reduxData?.loginDetails?.user?.id === item?.attributes?.userid?.data?.id
    }

    checkForumDelete = () => {
        return this.props?.reduxData?.loginDetails?.user?.id === this.state.fetchedData?.attributes?.posted_by?.data?.id
    }

    deleteForum = () => {
        this.setState({ loader: true });
        const id = this.props?.match?.params?.id;
        axiosInstance.delete(process.env.REACT_APP_BASE_URL + `api/forums/${id}`)
            .then(response => {
                this.setState({ forumDeleted: true })
            })
            .catch(error => {
            }).finally(() => {
                this.setState({ loader: false });
            });
    }

    render() {

        const { loader, fetchedData, commentAddedText } = this.state;

        return (
            <>
                {!loader &&
                    <div>
                        <div>
                            <Link className='btn-link-primary'
                                to={{ pathname: '/app/forum' }}>
                                <div className="d-inline-flex" >
                                    <img src={backlogo} alt="" style={{ width: '22px' }} />
                                    <span className="ms-1" style={{ color: '#333333', fontWeight: 400 }}>Back</span>
                                </div>
                            </Link>
                        </div>
                        <div className="forumDescription_body">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <div className="forumDescription_body_title">
                                        {fetchedData?.attributes?.title}
                                    </div>
                                    <div className="forumDescription_body_desc_subtitle">
                                        <span className="" style={{ textDecoration: 'underline', marginRight: '0.2rem' }}>
                                            {fetchedData?.attributes?.posted_by?.data?.attributes?.username}
                                        </span>{' '}
                                        asked {this.getDays(fetchedData?.attributes?.createdAt)} ago
                                    </div>
                                </div>
                                <div>
                                    {this.checkForumDelete() && <div className="d-flex " role="button" onClick={() => this.deleteForum()}>
                                        <div >
                                            <MdDeleteOutline size={'20px'} />
                                        </div>
                                        <span className="ms-1" style={{ color: '#333333', fontWeight: 400, fontSize: '1rem' }}>Delete</span>
                                    </div>}
                                </div>
                            </div>
                            <div className="mt-4">
                                <div className="content-container">
                                    <div dangerouslySetInnerHTML={{ __html: fetchedData?.attributes?.body }} />
                                </div>
                            </div>
                            <hr></hr>
                            <div className="d-flex align-items-start" >
                                <div onClick={() => this.toggleLikeForumLike(fetchedData?.attributes?.liked)} role="button">

                                    {fetchedData?.attributes?.liked ? <BiSolidLike size={'20px'} color={'#545cd8'} /> : <AiOutlineLike size={'20px'} />}

                                </div>
                                <span className="ms-1" style={{ color: '#333333', fontWeight: 400, fontSize: '1rem' }}>{this.getLikeCount(fetchedData?.attributes?.liked_by?.data?.length)}</span>
                            </div>
                            <div>
                                <div className="Comments_header">
                                    Comments
                                </div>
                                <div className="left_columnComment mt-4">
                                    <div>
                                        <FormGroup>
                                            <Input
                                                id="comment"
                                                name="text"
                                                placeholder="Add a comment"
                                                type="textarea"
                                                value={commentAddedText}
                                                onChange={this.handleCommentAddedChange}
                                            />
                                            <div className="left_columnComment_searchBtn mt-2">
                                                <Button className="" color="primary" style={{ borderRadius: '4px', height: '48px', fontSize: '1rem' }} onClick={this.addCommentClick} disabled={!commentAddedText}>
                                                    Respond
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </div>
                                </div>
                                <div>
                                    {fetchedData?.attributes?.user_comments?.data?.length > 0 ?
                                        fetchedData?.attributes?.user_comments?.data?.map((item, index) => {
                                            return (
                                                <>
                                                    <div className="comment_nameContainer">
                                                        <div className="header-rightContent-userNameInitial">{item?.attributes?.userid?.data?.attributes?.username?.substring(0, 1)}</div>
                                                        <div className="rightContent-userName">{item?.attributes?.userid?.data?.attributes?.username}</div>
                                                    </div>
                                                    <div className="commentDesc">
                                                        {item?.attributes?.comment}
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <div className="d-flex align-items-end pe-auto" role="button" onClick={() => this.toggleLikeCommentLike(item?.id, item?.attributes)}>
                                                            <div >
                                                                {item?.attributes?.liked ? <BiSolidLike size={'16px'} color={'#545cd8'} /> : <AiOutlineLike size={'16px'} />}
                                                            </div>
                                                            <span className="ms-1" style={{ color: '#333333', fontWeight: 400, fontSize: '0.75rem' }}>{this.getLikeCount(item?.attributes?.comments_liked_by?.data?.length)}</span>
                                                        </div>
                                                        {this.checkCommentDelete(item) && <div className="d-flex align-items-end" role="button" onClick={() => this.deleteComment(item?.id)}>
                                                            <div >
                                                                <MdDeleteOutline size={'16px'} />
                                                            </div>
                                                            <span className="ms-1" style={{ color: '#333333', fontWeight: 400, fontSize: '0.75rem' }}>Delete</span>
                                                        </div>}
                                                    </div>
                                                </>)
                                        })
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </div>}
                {loader &&
                    <div className="backdrop">
                        <div className="loader">
                            <Loader type="ball-pulse-rise" />
                        </div>
                    </div>
                }
                <SweetAlert title="Success!" show={this.state?.forumDeleted}
                    confirmBtnText='Go to dashboard'
                    onConfirm={() => { this.setState({ forumDeleted: false }); this.props.history.push('/app/forum'); }}>
                    Discussion has been successfully deleted.Thank you!
                </SweetAlert>
                <ToastContainer transition={Slide} />
            </>
        );
    }
}

export default connect(mapStateToProps)(ForumDescription);
