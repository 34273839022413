import { Route, Redirect } from "react-router-dom";
import React, {  Fragment, Component } from "react";
import { connect } from 'react-redux';
import HomePage from '../../Pages/HomePage/index'
import PreAuthLayout from "../PreAuth";
import PostAuthLayout from "../../AppLayout/PostAuth";

const mapStateToProps = (state) => {
    return {
        reduxData: state.counterSlice
    };
};



class PrivateRoute extends Component {

    render() {
        const { component: Component, isAuthenticated, ...rest } = this.props;

        return (
            <Route
                {...rest}
                render={props =>
                    isAuthenticated ? (
                        <Component {...props} />
                    ) : (
                        <Redirect to="/auth/login" />
                    )
                }
            />
        );
    }
}

const AppMain = (props) => {

    return (
        <Fragment>
            <Route path="/" component={HomePage} exact />
            <Route path="/auth" component={PreAuthLayout} />
            <PrivateRoute path="/app" component={PostAuthLayout} isAuthenticated={props?.reduxData?.loginDetails?.jwt} />
            <Route exact path="/" render={() => (
                <Redirect to="/" />
            )} />
        </Fragment>
    )
};

export default connect(mapStateToProps)(AppMain);
