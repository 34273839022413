import React, { Component } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import dot1 from "../../assets/images/png/MaskGroup101.png";
import ErrorPopup from "../Components/ErrorPopup";
import SweetAlert from "react-bootstrap-sweetalert";

const mapStateToProps = (state) => {
  return {
    reduxData: state.counterSlice,
  };
};

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
        userName: props?.reduxData?.loginDetails?.user?.username,
        subscriptionLevel:"",
        openErrorPopup:false
    };
  }

  componentDidMount() {
    let highestIdObject = { id: 0 };

    this.props?.reduxData?.loginSubscriptionDetail?.forEach(item => {
        if (item.id > highestIdObject?.id) {
            highestIdObject = { ...item };
        }
    });
    this.setState({subscriptionLevel:highestIdObject?.attributes?.plan?.toLowerCase()})
  }

  getStartedClicked = (id) => {
    if(id === "1"){
        this.props?.history?.push({ pathname: '/app/cancerType' })
    }else if(id === "2"){
      if(this.state.subscriptionLevel === "gold"){
        this.props?.history?.push({ pathname: '/app/discover/myProject' })
      }else{
        this.setState({ openErrorPopup: true });
      }
       
    }else{
        this.props?.history?.push({ pathname: '/app/forum' })

    }
  };

  closePopup = () => {
    this.setState({ openErrorPopup: false });
  };

  render() {
    const { userName } = this.state;
    const cardData = [
      {
        id:"1",
        heading: "Drug Repositioning  Database",
        description:
          "Free access to the basic features for a single time usage. Free access to the basic features for a single time usage. Free access to the basic features for a single time usage.",
      },
      {
        id:"2",
        heading: "Multi-Omics Network Analysis",
        description:
          "Explore our extensive database and tools for drug discovery. Free access to the basic features for a single time usage. Free access to the basic features for a single time usage.",
      },
      {
        id:"3",
        heading: "Forum",
        description:
          "Join discussions and collaborate with peers. Free access to the basic features for a single time usage. Free access to the basic features for a single time usage.",
      },
    ];

    return (
      <div className="landing-main">
        <div className="landing-top">
          <div className="landing-left">
            <img src={dot1} alt="Decoration" />
          </div>
          <div className="landing-content">
            <div className="heading-landing">Hello {userName.split(' ')[0]}, welcome to CATOS</div>
            <div className="heading-description">
              <div className="description-inner">
                Free access to the basic features for a single time usage. Free
                access to the basic features for a single time usage. Free
                access to the basic features for a single time usage.
              </div>
            </div>
          </div>
        </div>
          <div className="nav-cards-container">
            {cardData.map((card, index) => (
              <div className="nav-cards" key={index}>
                <div className="card-top">
                <div
                  className="card-heading"
                  dangerouslySetInnerHTML={{
                    __html: card.heading,
                  }}
                ></div>
                <div className="card-description">{card.description}</div>
                </div>
                 <div  className="card-bottom">
                 <Button
                  color="primary"
                  className="get-started-button"
                  onClick={() => this.getStartedClicked(card.id)}
                >
                  Get Started
                </Button>
                 </div>
              </div>
            ))}
          </div>
          <div className="landing-right">
            <img src={dot1} alt="Decoration" />
          </div>
          <SweetAlert
            custom
            show={this.state.openErrorPopup}
            customButtons={<React.Fragment></React.Fragment>}
            style={{ width: "auto", padding: "1rem" }}
          >
            <ErrorPopup
              closeAlert={this.closePopup}
              title="Access to this feature is exclusive to Gold members. Please update your subscription to gain access."
              buttonTitle="Ok"
            ></ErrorPopup>
          </SweetAlert>
      </div>
    );
  }
}

export default connect(mapStateToProps)(LandingPage);
