import React, { Component } from "react";
import backlogo from '../../assets/images/svg/Back.svg'
import { Link } from 'react-router-dom';
import Password from "./password";
import Billing from "./billing";
import { connect } from 'react-redux';
import Profile from "./Profile";
import { setloginSubscriptionDetail } from "../../reducers/counterSlice";

const mapStateToProps = (state) => {
  return {
    reduxData: state.counterSlice
  };
};

const mapDispatchToProps = {
  setloginSubscriptionDetail
};
class ProfileEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: props?.location?.state?.param ?? 'profile',
      userName: props?.reduxData?.loginDetails?.user?.username,
      email: props?.reduxData?.loginDetails?.user?.email,
      subscriptionPlan: '',
    };

  }

  componentDidMount() {

    let highestIdObject = { id: 0 };


    this.props?.reduxData?.loginSubscriptionDetail?.forEach(item => {
      if (item.id > highestIdObject?.id) {
        highestIdObject = { ...item };
      }
    });

    this.setState({ subscriptionPlan: highestIdObject?.attributes?.plan })
  };

  handleTabChange = (tab) => {
    this.setState({ selectedTab: tab });
  };

  setloginSubscriptionDetail = (data) => {
    this.props?.setloginSubscriptionDetail(data)
  }
  render() {
    const { selectedTab, userName, email } = this.state;


    return (
      <>
        <div className="">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-12 mb-3">
              <div className="card card-cancerType" style={{ 'backgroundColor': '#F2F2F2' }}>
                <div className="card-body">
                  <Link className="btn-link-primary" to={{ pathname: '/app/dashboard' }}>
                    <div className="d-inline-flex">
                      <img src={backlogo} alt="Back" />
                      <span className="ms-1" style={{ color: '#333333', fontWeight: 400 }}>Back</span>
                    </div>
                  </Link>
                  <div className="Profile-details">
                    <div className="top-section-profile-details d-flex align-items-end">
                      <div className="userNameInitial d-flex">
                        <div className="header-rightContent-userNameInitial">{userName.substring(0, 1)}</div>
                      </div>
                      <div className="name-profile-details">{userName}</div>
                    </div>
                    <div className="email-section-profile-details">{email}</div>
                  </div>
                  <div className="d-grid gap-2">
                    <div
                      className={`tabProfileEdit ${selectedTab === 'profile' ? 'active' : ''}`}
                      onClick={() => this.handleTabChange('profile')}>
                      Profile
                    </div>
                    <div
                      className={`tabProfileEdit ${selectedTab === 'password' ? 'active' : ''}`}
                      onClick={() => this.handleTabChange('password')}>
                      Password
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-8 col-sm-12 mb-3">
              <div className="card card-cancerType" style={{ backgroundColor: '#F2F2F2', minHeight: this.state.drugList?.data?.length > 0 ? 'auto' : '75vh' }}>
                <div className="card-body card-body-cancerType d-grid gap-2" >
                  {selectedTab === 'profile' && <Profile />}
                  {selectedTab === 'password' && <Password />}
                  {selectedTab === 'billing' && <Billing loginSubscriptionDetail={this.props.reduxData?.loginSubscriptionDetail} loginDetails={this.props.reduxData?.loginDetails} setloginSubscriptionDetail={this.setloginSubscriptionDetail} />}
                </div>

              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit);

