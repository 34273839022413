
import React from "react";

const ErrorPopup = ({ closeAlert,title,buttonTitle }) => {

  return (
    <div className="delete-project-container">
      <div style={{fontSize:"14px",fontWeight:"600",}}>
       {title}
      </div>
      <div className="button-popup-container">
        <button
          className="btn btn-lg btn-primary btn-delete"
          onClick={closeAlert}
        >
          {buttonTitle}
        </button>
      </div>
    </div>
  );
};

export default ErrorPopup;
