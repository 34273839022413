import React, { Component } from "react";
import { FormGroup, Button, Label, Input } from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import axiosInstance from "../configuration/Request";
import Loader from "react-loaders";
import { setNetworkAnalysisDetails } from "../../reducers/counterSlice";
import backlogo from "../../assets/images/svg/Back.svg";
import { Link } from "react-router-dom";

const mapStateToProps = (state) => {
  return {
    reduxData: state.counterSlice,
  };
};

const mapDispatchToProps = {
  setNetworkAnalysisDetails,
};

class CreateNetwork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      selectedDataSource: null,
      selectedSampleType: null,
      selectedRiskLevel: null,
      selectedTherapeuticResponse: null,
      selectedBootstrapping: "Yes",
      cancerTypeOptions: [],
      selectedCancerType: "Tumor",
      selectedSourceGene: null,
      selectedTargetGene: null,
      loader: false,
      errorMessage: "",
      cancerTypeError: "",
      sourceGeneError: "",
      targetGeneError: "",
      // normalTumorError: "",
      dataSourceError: "",
      sampleTypeError: "",
      riskLevelError: "",
      therapeuticResponseError: "",
      dataSource: [],
      sampleType: [],
      riskLevel: [],
      therapeuticResponse: [],
    };
  }
  bootstrapping = ["Yes", "No"];
  cancerTypeOptions = ["Normal", "Tumor"];
  drugTypeOptions = [
    "CNV",
    "mRNA",
    "Protein",
    "Mutation",
    "Fusion",
    "Methylation",
  ];

  componentDidMount() {
    this.setState({ loader: true });
    axiosInstance
      .get(`${process.env.REACT_APP_BASE_URL}api/network-dropdowns`)
      .then((res) => {
        let cancerType = res?.data?.dropdownData
          ?.filter((item) => item?.cancer_type)
          .map((item) => ({
            label: item.cancer_type,
            options: item?.histological_subtypes
              ?.filter((subtype) => subtype !== null)
              .map((subtype) => ({
                value: subtype,
                label: subtype,
                cancer_type: item.cancer_type,
              })),
          }));

        let dataSourceOption = res?.data?.dataSources
          ?.filter((item) => item !== null)
          .map((item) => ({
            value: item,
            label: item ? this.capitalizeWords(item) : "",
          }));

        let therapeuticResponseOption = res?.data?.therapeuticResponses
          ?.filter((item) => item !== null)
          ?.map((item) => ({
            value: item,
            label: item,
          }));

        let sampleTypeOption = res?.data?.sampleTypes
          ?.filter((item) => item !== null)
          ?.map((item) => ({
            value: item,
            label: item ? this.capitalizeWords(item) : "",
          }));
        let riskLevelOption;
        const riskLevels = res?.data?.riskLevels || [];
        if (riskLevels.includes("all risk levels")) {
          riskLevelOption = riskLevels
            .filter((item) => item !== null && item !== "NA")
            .map((item) => ({
              value: item,
              label: this.capitalizeWords(item),
            }));
          this.setState({
            selectedRiskLevel:
              riskLevelOption.length > 0
                ? { value: "all risk levels", label: "All Risk Levels" }
                : null,
          });
        } else {
          riskLevelOption = riskLevels
            ?.filter((item) => item !== null)
            .map((item) => {
              if (item === "NA") {
                return {
                  value: item,
                  label: "All Risk Levels",
                };
              } else {
                return {
                  value: item,
                  label: this.capitalizeWords(item), 
                };
              }
            });
          this.setState({
            selectedRiskLevel:
              riskLevelOption.length > 0
                ? { value: "NA", label: "All Risk Levels" }
                : null,
          });
        }

        this.setState({
          cancerTypeOptions: cancerType,
          dataSource: dataSourceOption,
          sampleType: sampleTypeOption,
          riskLevel: riskLevelOption,
          therapeuticResponse: therapeuticResponseOption,
        });

        this.setState({
          selectedDataSource:
            dataSourceOption.length > 0
              ? { value: "TCGA", label: "TCGA" }
              : null,
          selectedSampleType:
            sampleTypeOption.length > 0
              ? { value: "bulk tumor", label: "Bulk Tumor" }
              : null,
          // selectedRiskLevel: riskLevelOption.length > 0 ? { value: "all risk levels", label: "All Risk Levels" } : null,
          selectedTherapeuticResponse:
            therapeuticResponseOption.length > 0
              ? { value: "NA", label: "NA" }
              : null,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => this.setState({ loader: false }));
  }

  capitalizeWords = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  handleSelectChange = (event, type) => {
    if (type === "cancer type") {
      this.setState({ selectedOption: event, cancerTypeError: "" });
    } else if (type === "data source") {
      this.setState({ selectedDataSource: event, dataSourceError: "" });
    } else if (type === "sample type") {
      this.setState({ selectedSampleType: event, sampleTypeError: "" });
    } else if (type === "risk level") {
      this.setState({ selectedRiskLevel: event, riskLevelError: "" });
    } else if (type === "therapeutic response") {
      this.setState({
        selectedTherapeuticResponse: event,
        therapeuticResponseError: "",
      });
    }
  };

  handleRadioChange = (value, type) => {
    if (type === "cancer") {
      this.setState({
        selectedCancerType: value,
        //  normalTumorError: ""
      });
    } else if (type === "source") {
      this.setState({ selectedSourceGene: value, sourceGeneError: "" });
    } else if (type === "target") {
      this.setState({ selectedTargetGene: value, targetGeneError: "" });
    } else if (type === "bootstrapping") {
      this.setState({ selectedBootstrapping: value });
    }
  };

  onSubmitClick = () => {
    let hasError = false;

    if (!this.state.selectedOption) {
      this.setState({ cancerTypeError: "Please select a Cancer Type." });
      hasError = true;
    }

    if (!this.state.selectedSourceGene) {
      this.setState({ sourceGeneError: "Please select a Source Gene." });
      hasError = true;
    }

    if (!this.state.selectedTargetGene) {
      this.setState({ targetGeneError: "Please select a Target Gene." });
      hasError = true;
    }

    // if (!this.state.selectedCancerType) {
    //   this.setState({ normalTumorError: "Please select a normal/tumor." });
    //   hasError = true;
    // }

    if (!this.state.selectedSampleType) {
      this.setState({ sampleTypeError: "Please select a sample type." });
      hasError = true;
    }
    if (!this.state.selectedRiskLevel) {
      this.setState({ riskLevelError: "Please select a risk level." });
      hasError = true;
    }
    if (!this.state.selectedDataSource) {
      this.setState({ dataSourceError: "Please select a data source." });
      hasError = true;
    }
    if (!this.state.selectedTherapeuticResponse) {
      this.setState({
        therapeuticResponseError: "Please select a therapeutic response.",
      });
      hasError = true;
    }

    if (!hasError) {
      this.props.setNetworkAnalysisDetails({
        histological_subtype: {
          label: this.state.selectedOption.label,
          value: this.state.selectedOption.value,
        },
        cancer_type: this.state.selectedOption.cancer_type,
        source_gene: this.state.selectedSourceGene,
        target_gene: this.state.selectedTargetGene,
        normal_tumor: this.state.selectedCancerType,
        sample_type: this.state.selectedSampleType,
        risk_level: this.state.selectedRiskLevel,
        therapeutic_response: this.state.selectedTherapeuticResponse,
        bootstrap_yes_no: this.state.selectedBootstrapping.toLowerCase(),
        data_source: this.state.selectedDataSource,
      });
      this.props.history.push("/app/discover/parameterSetting");
    }
  };

  render() {
    const {
      selectedOption,
      cancerTypeError,
      sourceGeneError,
      targetGeneError,
      // normalTumorError,
      therapeuticResponseError,
      riskLevelError,
      sampleTypeError,
      dataSourceError,
    } = this.state;

    return (
      <div className="outer-container">
        <div className="main-container">
          <div className="top-container">
            <Link
              className="btn-link-primary "
              to={{ pathname: "/app/discover/myProject" }}
            >
              <div className="d-inline-flex">
                <img src={backlogo} alt="" />
                <span
                  style={{ color: "#333333", fontWeight: 400 }}
                  className="ms-2"
                >
                  Back
                </span>
              </div>
            </Link>
            <div className="form-header">Network Analysis</div>
            <div className="network-form-container">
              <div>
                <FormGroup className="select-cancer-type ms-1 mt-1">
                  <Select
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        height: "48px",
                        cursor: "pointer",
                        borderColor: cancerTypeError
                          ? "red"
                          : baseStyles.borderColor,
                      }),
                      option: (base) => ({
                        ...base,
                        cursor: "pointer",
                        fontSize: "14px",
                        paddingLeft: "30px",
                      }),
                      groupHeading: (base) => ({
                        ...base,
                        fontSize: "14px",
                        color: "#000000",
                      }),
                    }}
                    value={selectedOption}
                    onChange={(e) => this.handleSelectChange(e, "cancer type")}
                    options={this.state.cancerTypeOptions}
                    placeholder="Select a cancer type*"
                  />
                  <div className="error-message">
                    {cancerTypeError && (
                      <div className="text-danger mt-1">{cancerTypeError}</div>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="mt-1">
                <div className="row ms-1">
                  {this.cancerTypeOptions.map((label, index) => (
                    <div className="col-md-2 col-sm-4 col-6" key={index}>
                      <FormGroup check style={{ marginBottom: "0px" }}>
                        <Input
                          type="radio"
                          name="cancerType"
                          id={`cancer-radio-${index}`}
                          onChange={() =>
                            this.handleRadioChange(label, "cancer")
                          }
                          checked={this.state.selectedCancerType === label}
                          style={{ cursor: "pointer" }}
                        />
                        <Label
                          for={`cancer-radio-${index}`}
                          check
                          className="radio-label ml-2"
                        >
                          {label}
                        </Label>
                      </FormGroup>
                    </div>
                  ))}
                </div>
                <div className="error-message ms-1">
                  {/* {normalTumorError && (
                    <div className="text-danger">{normalTumorError}</div>
                  )} */}
                </div>
              </div>
              <hr style={{ width: "100%", marginTop: "4px" }} />
              <div className="drugType-header">Select Drug Type</div>
              <div className="drug-type-container mb-2">
                <div className="source-gene-div">
                  <div className="gene-title">Source Gene*</div>
                  <div className="row ms-1">
                    {this.drugTypeOptions.map((label, index) => (
                      <div
                        className="col-xl-3 col-lg-4 col-md-6 col-sm-3 col-6"
                        key={index}
                      >
                        <div
                          className="radio-button-container"
                          onClick={() =>
                            this.handleRadioChange(label, "source")
                          }
                        >
                          <FormGroup check>
                            <Input
                              style={{ cursor: "pointer" }}
                              type="radio"
                              name="sourceGene"
                              id={`source-radio-${index}`}
                              onChange={() =>
                                this.handleRadioChange(label, "source")
                              }
                              checked={this.state.selectedSourceGene === label}
                            />
                            <Label
                              for={`source-radio-${index}`}
                              check
                              className="radio-label ml-2"
                            >
                              {label}
                            </Label>
                          </FormGroup>
                        </div>
                      </div>
                    ))}
                    <div className="error-message">
                      {sourceGeneError && (
                        <div className="text-danger">{sourceGeneError}</div>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    borderLeft: "1px solid #c8c9cb",
                    height: "auto",
                    alignSelf: "stretch",
                    margin: "0 20px",
                  }}
                ></div>
                <div className="target-gene-div">
                  <div className="gene-title">Target Gene*</div>
                  <div className="row">
                    {this.drugTypeOptions.map((label, index) => (
                      <div
                        className="col-xl-3 col-lg-4 col-md-6 col-sm-3 col-6"
                        key={index}
                      >
                        <div
                          className="radio-button-container"
                          onClick={() =>
                            this.handleRadioChange(label, "target")
                          }
                        >
                          <FormGroup check>
                            <Input
                              style={{ cursor: "pointer" }}
                              type="radio"
                              name="targetGene"
                              id={`target-radio-${index}`}
                              onChange={() =>
                                this.handleRadioChange(label, "target")
                              }
                              checked={this.state.selectedTargetGene === label}
                            />
                            <Label
                              for={`target-radio-${index}`}
                              check
                              className="radio-label ml-2"
                            >
                              {label}
                            </Label>
                          </FormGroup>
                        </div>
                      </div>
                    ))}
                    <div className="error-message">
                      {targetGeneError && (
                        <div className="text-danger">{targetGeneError}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <hr style={{ width: "100%", marginTop: "4px" }} />

              <div className="sections-container ">
                <div className="sections-inner ">
                  <div className="input-title">Data Source*</div>
                  <FormGroup className="create-select">
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          height: "48px",
                          cursor: "pointer",
                          // borderColor: baseStyles.borderColor,
                          borderColor: dataSourceError
                            ? "red"
                            : baseStyles.borderColor,
                        }),
                        option: (base) => ({
                          ...base,
                          cursor: "pointer",
                        }),
                      }}
                      value={this.state.selectedDataSource}
                      onChange={(e) =>
                        this.handleSelectChange(e, "data source")
                      }
                      options={this.state.dataSource}
                      placeholder="Select Data Source..."
                    />
                    <div className="error-message">
                      {dataSourceError && (
                        <div className="text-danger mt-1">
                          {dataSourceError}
                        </div>
                      )}
                    </div>
                  </FormGroup>
                </div>
                <div className="sections-inner right-section">
                  <div className="input-title">Sample Type*</div>
                  <FormGroup className="create-select">
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          height: "48px",
                          cursor: "pointer",
                          borderColor: sampleTypeError
                            ? "red"
                            : baseStyles.borderColor,
                        }),
                        option: (base) => ({
                          ...base,
                          cursor: "pointer",
                        }),
                      }}
                      value={this.state.selectedSampleType}
                      onChange={(e) =>
                        this.handleSelectChange(e, "sample type")
                      }
                      options={this.state.sampleType}
                      placeholder="Select Sample Type..."
                    />
                    <div className="error-message">
                      {sampleTypeError && (
                        <div className="text-danger mt-1">
                          {sampleTypeError}
                        </div>
                      )}
                    </div>
                  </FormGroup>
                </div>
              </div>

              <div className="sections-container">
                <div className="sections-inner">
                  <div className="input-title">Risk Level*</div>
                  <FormGroup className="create-select">
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          height: "48px",
                          cursor: "pointer",
                          borderColor: riskLevelError
                            ? "red"
                            : baseStyles.borderColor,
                        }),
                        option: (base) => ({
                          ...base,
                          cursor: "pointer",
                        }),
                      }}
                      value={this.state.selectedRiskLevel}
                      onChange={(e) => this.handleSelectChange(e, "risk level")}
                      options={this.state.riskLevel}
                      placeholder="Select Risk Level ..."
                    />
                    <div className="error-message">
                      {riskLevelError && (
                        <div className="text-danger mt-1">{riskLevelError}</div>
                      )}
                    </div>
                  </FormGroup>
                </div>
                <div className="sections-inner right-section">
                  <div className="input-title">Therapeutic Response*</div>
                  <FormGroup className="create-select">
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          height: "48px",
                          cursor: "pointer",
                          borderColor: therapeuticResponseError
                            ? "red"
                            : baseStyles.borderColor,
                        }),
                        option: (base) => ({
                          ...base,
                          cursor: "pointer",
                        }),
                      }}
                      value={this.state.selectedTherapeuticResponse}
                      onChange={(e) =>
                        this.handleSelectChange(e, "therapeutic response")
                      }
                      options={this.state.therapeuticResponse}
                      placeholder="Select Therapeutic Response..."
                    />
                    <div className="error-message">
                      {therapeuticResponseError && (
                        <div className="text-danger mt-1">
                          {therapeuticResponseError}
                        </div>
                      )}
                    </div>
                  </FormGroup>
                </div>
              </div>

              <div className="mt-3 mb-5">
                <div className="input-title">The bootstrapping*</div>
                <div className="row ms-1">
                  {this.bootstrapping.map((label, index) => (
                    <div className="col-md-2 col-sm-4 col-6" key={index}>
                      <FormGroup check style={{ marginBottom: "0px" }}>
                        <Input
                          type="radio"
                          name="Bootstrapping"
                          id={`Bootstrapping-radio-${index}`}
                          onChange={() =>
                            this.handleRadioChange(label, "bootstrapping")
                          }
                          checked={this.state.selectedBootstrapping === label}
                          style={{ cursor: "pointer" }}
                        />
                        <Label
                          for={`Bootstrapping-radio-${index}`}
                          check
                          className="radio-label ml-2"
                        >
                          {label}
                        </Label>
                      </FormGroup>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-container">
            <Button
              className="next-button me-2"
              color="primary"
              onClick={this.onSubmitClick}
            >
              Next
            </Button>
          </div>
        </div>
        {this.state.loader && (
          <div className="backdrop">
            <div className="loader">
              <Loader type="ball-pulse-rise" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNetwork);
