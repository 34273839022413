// // dateUtils.js
// export function formatDate(utcDateString) {
//     const submitTimeUTC = new Date(utcDateString);

//     // Format IST time to 12-hour format with AM/PM
//     const options = {
//         year: 'numeric',
//         month: '2-digit',
//         day: '2-digit',
//         hour: '2-digit',
//         minute: '2-digit',
//         // second: '2-digit',
//         hour12: true
//     };
//     const formattedDate = submitTimeUTC.toLocaleString('en-IN', options);

//     // Force AM/PM to uppercase
//     return formattedDate.replace('am', 'AM').replace('pm', 'PM');
// }


export function formatDate(utcDateString) {
    const submitTimeUTC = new Date(utcDateString);

    // Convert UTC to EST by adjusting the time zone offset
    const estOffset = -5 * 60; // EST is UTC-5 hours
    const submitTimeEST = new Date(submitTimeUTC.getTime() + estOffset * 60 * 1000);

    const year = submitTimeEST.getUTCFullYear();
    const month = String(submitTimeEST.getUTCMonth() + 1).padStart(2, '0');
    const day = String(submitTimeEST.getUTCDate()).padStart(2, '0');

    let hours = submitTimeEST.getUTCHours();
    const minutes = String(submitTimeEST.getUTCMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedHours = String(hours).padStart(2, '0');

    const formattedDate = `${month}-${day}-${year}`;
    const formattedTime = `${formattedHours}:${minutes} ${ampm}`;

    return `${formattedDate} ${formattedTime}`;
}



