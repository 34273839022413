import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 0,
  basicDetails: {},
  primaryRole: {},
  registrationEmail: "",
  cancerType: {},
  forgetPasswordEmail: "",
  loginDetails: "",
  subscriptionDetail: {},
  loginSubscriptionDetail: [],
  upgradePopup: false,
  logoutPopup: false,
  projectId: "",
  networkAnalysisDetails: {},
  networkSuccess: false,
  cytoscapeData: {},
};

const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setBasicDetails(state, action) {
      state.basicDetails = action?.payload;
    },
    setPrimaryRole(state, action) {
      state.primaryRole = action?.payload;
    },
    setRegistrationEmail(state, action) {
      state.registrationEmail = action?.payload;
    },
    setCancerType(state, action) {
      state.cancerType = action?.payload;
    },
    setForgetPasswordEmail(state, action) {
      state.forgetPasswordEmail = action?.payload;
    },
    setloginDetails(state, action) {
      state.loginDetails = action?.payload;
    },
    setSubscriptionDetail(state, action) {
      state.subscriptionDetail = action?.payload;
    },
    setloginSubscriptionDetail(state, action) {
      state.loginSubscriptionDetail = action?.payload;
    },
    setupgradePopup(state, action) {
      state.upgradePopup = action?.payload;
    },
    setlogoutPopup(state, action) {
      state.logoutPopup = action?.payload;
    },
    setProjectId(state, action) {
      state.projectId = action?.payload;
    },
    setNetworkAnalysisDetails(state, action) {
      state.networkAnalysisDetails = action?.payload;
    },
    setNetworkSuccess(state, action) {
      state.networkSuccess = action?.payload;
    },
    setCytoscape(state, action) {
      state.cytoscapeData = action?.payload;
    },
  },
});

export const {
  setBasicDetails,
  setPrimaryRole,
  setRegistrationEmail,
  setCancerType,
  setForgetPasswordEmail,
  setloginDetails,
  setSubscriptionDetail,
  setloginSubscriptionDetail,
  setupgradePopup,
  setlogoutPopup,
  setProjectId,
  setNetworkAnalysisDetails,
  setNetworkSuccess,
  setCytoscape
} = counterSlice.actions;
export default counterSlice.reducer;
