import React, { Component } from 'react';
import { Button } from "reactstrap";
import groupPhoto from '../../assets/images/png/MassGroup102.png';
import fund1 from '../../assets/images/png/Image80.png';
import fund2 from '../../assets/images/png/Group167787.png';
import fund3 from '../../assets/images/png/Image83.png';
import fund4 from '../../assets/images/png/Image84.png';
import fund5 from '../../assets/images/png/Group167772.png';
import logo1 from '../../assets/images/png/Group167776.png';
import logo2 from '../../assets/images/png/Group167777.png';
import logo3 from '../../assets/images/png/Group167782.png';
import logo4 from '../../assets/images/png/Group167780.png';
import dot1 from '../../assets/images/png/MaskGroup101.png';
import dot2 from '../../assets/images/png/MaskGroup109.png';
import dot3 from '../../assets/images/png/MaskGroup108.png';
import ribon from '../../assets/images/png/MaskGroup106.png';
import sostoslogo from '../../assets/images/svg/Sostoslogo.svg';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
      reduxData: state.counterSlice
  };
};

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  getStartedClicked = ()=> {
    if(this?.props?.reduxData?.loginDetails?.jwt)
    {
      this.props?.history?.push({ pathname: '/app/dashboard' })
    }else{
      this.props?.history?.push({ pathname: '/auth/login' })
    }
  }
  render() {
    return (
      <div style={{}}>
        <div className='overflow-hidden-section' >
          <div className="container-fluid fixed-top" >
            <div className="row">
              <div className="">
                <div className="catos-title">CATOS</div>
                <div className='catos-description'>
                  <u>Ca</u>ncer <u>T</u>reatment <u>O</u>ptimization <u>S</u>olutions
                </div>
               </div>
            </div>
          </div>
          <div className= "hero-section" >
            <div className='content-wrapper' >
              <div className=" heading-container">
                Revolutionizing Cancer Care with AI-Powered Precision Medicine
              </div>
              <div className='button-container ' >
                  <Button  color="primary" className="get-started-button"  onClick={this.getStartedClicked}>
                    Get Started
                  </Button>              
              </div>
              <img src={dot1} className='dot-image' />
            </div>
            <div className='image-container' >
              <img className='people-photo' src={groupPhoto} alt=""  />
              <div className='gradient-overlay' >
              </div>
            </div>
          </div>
        </div>
        
        <div class="funding-section ">
          <div class="funding-text-wrapper">
            <div class="funding-title">Funded by</div>
            <div class="funding-description">Our technologies and research have received funding from prestigious institutions.</div>
          </div>
          <div class=" funding-logos">
            <div class="logo-container">
              <img src={fund1} alt="" class="funding-logo-1" />
            </div>
            <div class="logo-container">
              <img src={fund2} alt="" class="funding-logo-2" />
            </div>
            <div class="logo-container">
              <img src={fund3} alt="" class="funding-logo-3"/>
            </div>
            <div class="logo-container">
              <img src={fund4} alt="" class="funding-logo-4"/>
            </div>
            <div class="logo-container">
              <img src={fund5} alt="" class="funding-logo-5"/>
            </div>
          </div>
        </div>
       
        <div class="about-us-section " >
          <div class="about-us-content">
            <div class="about-us-title">About Us</div>
            <div class="about-us-description">
              Sostos LLC is a pioneering provider of advanced artificial intelligence (AI) and big data technologies, alongside health analytics services specifically tailored for precision medicine. Our <u>C</u>ancer <u>T</u>reatment <u>O</u>ptimization <u>S</u>olutions - <u>CATOS</u> products are designed to facilitate personalized cancer care at every stage of the patient journey, from initial cancer screening to the identification of new drug options for patients who have not responded to previous therapies.
            </div>
           
            <div className=' d-flex services-container' >
                <div style={{flexGrow: 1,}}>
                  <div className='service-icon-wrapper'>
                    <img src={logo1}className='.service-icon-1' alt=''/>
                  </div>
                  <div className='service-title'>
                    Personalized Cancer Care Solutions
                  </div>
                </div>
                <div style={{flexGrow: 1,}}>
                  <div className='service-icon-wrapper'>
                    <img src={logo2} className='.service-icon-2' alt=''/>
                  </div>
                  <div className='service-title'>
                    AI Technology/ Software
                  </div>
                </div>
                <div style={{flexGrow: 1,}}>
                  <div className='service-icon-wrapper' >
                    <img src={logo3} className='.service-icon-3' alt=''/>
                  </div>
                  <div className='service-title'>
                    Therapeutic Compound Identification
                  </div>
                </div>
                <div style={{flexGrow: 1,}}>
                  <div className='service-icon-wrapper' >
                    <img src={logo4} className='.service-icon-4' alt=''/>
                  </div>
                  <div className='service-title'>
                    Cancer Theranostics Improvement
                  </div>
                </div>
              </div>
          </div>
          <img src={dot1} alt="" class="about-us-dot" style={{position:"absolute",rotate:"145deg",bottom:"25rem",left:"84rem", zIndex:"-1"}}/>
        </div>
       
        <div className=' join-us-container' >
          <div className='dot-2-image' >
            <img src={dot2}  alt=''/>
          </div>
          <div className='ribon-image'>
              <img src={ribon} />
            </div>
          <div className='join-us-main'  >
            <div className='join-us-sub'>
              <div className="join-us-title">
                Join Us
              </div>
              <div className="join-us-des" >
                To advance in the field of precision oncology through innovative AI and big data solutions, ensuring personalized, effective, and cutting-edge care for cancer patients.
              </div>
              <div style={{marginTop:"1.75rem"}}>
                    <Button  color="primary" className="get-started-button" onClick={this.getStartedClicked}>
                     Get Started
                    </Button>
              </div>
            </div>
          </div>
          <div className='dot-3-image ' >
            <img src={dot3} alt=''/>
          </div>
          
        </div>
        
        <div class="footer">
          <div class="footer-content ">
            <div class="footer-logo-wrapper" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} role='button'>
              <img src={sostoslogo} alt="Sostos Logo" class="footer-logo"/>
            </div>
            <div class="footer-details">
              <h2 class="catos-title">CATOS</h2>
              <p class="catos-description"><u>Ca</u>ncer <u>T</u>reatment <u>O</u>ptimization <u>S</u>olutions</p>
            </div>
          </div>
          <div class="copyright" >
           ©2024 by Sostos LLC. All rights reserved.
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(HomePage);