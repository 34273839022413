import React, { Fragment } from "react";
import { Route, useLocation } from "react-router-dom";
import CRMDashboard from "../../Pages/SOSTOSDashboard";
import cancerTypeSelectionPage from "../../Pages/SOSTOSDashboard/cancerType";
import DrugPage from "../../Pages/SOSTOSDashboard/drugpage";
import Header from "../../Pages/Header/Header";
import ProfileEdit from "../../Pages/ProfileEdit/index";
import ForumDashboard from "../../Pages/Forum/ForumDashboard";
import NewDiscussion from "../../Pages/Forum/NewDiscussion";
import ForumDescription from "../../Pages/Forum/ForumDescription";
import Guidelines from "../../Pages/Forum/Guidelines";
import createNetwork from "../../Pages/Discover/createNetwork";
import parameterSetting from "../../Pages/Discover/parameterSetting";
import myProject from "../../Pages/Discover/myProject";
import furtherAnalysis from "../../Pages/Discover/furtherAnalysis";
import viewNetwork from "../../Pages/Discover/viewNetwork";
import DrugDiscover from "../../Pages/Discover/DrugDiscover";
import LandingPage from "../../Pages/LandingPage";
import SubscriptionCheck from "../../Pages/Components/SubscriptionCheck";

const PostAuthLayout = ({ match, history }) => {
  const location = useLocation();

  return (
    <Fragment>
      <div className="">
          <div>
            <Header location={location} history={history} />
          </div>
        <div className="app-main">
          <div className="app-main__outer">
            <div className="app-main__inner">
              <Route
                path={`${match.url}/cancerType`}
                component={CRMDashboard}
              />
              <Route
                path={`${match.url}/cancerTypeSelection`}
                component={cancerTypeSelectionPage}
              />
              <Route path={`${match.url}/drugpage`} component={DrugPage} />
              <Route
                path={`${match.url}/profileEdit`}
                component={ProfileEdit}
              />
              <Route path={`${match.url}/forum`} component={ForumDashboard} />
              <Route path={`${match.url}/forumNew`} component={NewDiscussion} />
              <Route
                path={`${match.url}/forumDescription/:id`}
                component={ForumDescription}
              />
              <Route path={`${match.url}/guidelines`} component={Guidelines} />
              <Route
                path={`${match.url}/discover/newNetwork`}
                component={SubscriptionCheck(createNetwork)}
              />
              <Route
                path={`${match.url}/discover/parameterSetting`}
                component={SubscriptionCheck(parameterSetting)}
              />
              <Route
                path={`${match.url}/discover/myProject`}
                component={SubscriptionCheck(myProject)}
              />
              <Route
                path={`${match.url}/discover/furtherAnalysis`}
                component={SubscriptionCheck(furtherAnalysis)}
              />
              <Route
                path={`${match.url}/discover/viewNetwork`}
                component={SubscriptionCheck(viewNetwork)}
              />
              <Route
                path={`${match.url}/discover/discover&Repurposing`}
                component={SubscriptionCheck(DrugDiscover)}
              />
              <Route path={`${match.url}/dashboard`} component={LandingPage} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PostAuthLayout;
