import React, { Component } from "react";
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import { TwitterIcon, EmailShareButton, EmailIcon, TwitterShareButton, LinkedinShareButton, LinkedinIcon, WhatsappShareButton, WhatsappIcon, FacebookShareButton, FacebookIcon } from 'react-share';
import shareIcon from '../../assets/images/png/ShareIcon.png';
import copyLink from '../../assets/images/png/copyLink.png';
import 'react-toastify/dist/ReactToastify.css';
import { toast, Bounce, Slide, ToastContainer } from "react-toastify";
import { BiSolidLike } from "react-icons/bi";
import { AiOutlineLike } from "react-icons/ai";
import SweetAlert from 'react-bootstrap-sweetalert';
import axiosInstance from "../configuration/Request";
import { removeEmojis } from "../ReusableComponent/RemoveEmoji";

const dropdownMenuStyle = {
    width: "200px",
    maxHeight: "300px",
    overflowY: "auto",
};

const mapStateToProps = (state) => {
    return {
        reduxData: state.counterSlice
    };
};

class DrugPageLikeQuery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            queryPopup: false,
            querySuccessPopup: false,
            queryErrorMsg: '',
            title: '',
            query: '',
            urlShare: '',
            shareDropDownOpen: false,
            liked: false,
            likeData: [],

        };

    }


    componentDidMount() {

        this.setState({ urlShare: window.location.href });
        this.likedDataAPI();
    };

    likedDataAPI = () => {
        const queryString = new URLSearchParams(this.props.url?.search);
        const id = queryString.get("id");


        axiosInstance.get(`${process.env.REACT_APP_BASE_URL}api/drug-informations?filters[disease_drugID][$eq]=${id}&populate=liked_by`)
            .then(res => {


                const data = res?.data?.data[0];
                const isLiked = res?.data?.data[0]?.attributes?.liked_by?.data?.some(item => item.id === this.props?.reduxData?.loginDetails?.user?.id);
                data.attributes.liked = isLiked;

                this.setState({ likeData: data })
            });

    }

    onQueryClicked = () => {
        this.setState((prevState) => ({
            queryPopup: !prevState.queryPopup, title: '', query: '', queryErrorMsg: ''
        }));
    }

    toggleDropdown = () => {
        this.setState((prevState) => ({
            shareDropDownOpen: !prevState.shareDropDownOpen,
        }));
    };

    handleTitleChange = (event) => {
        const filteredValue = removeEmojis(event.target.value);
        this.setState({ title: filteredValue })
    }

    handleQueryChange = (event) => {
        const filteredValue = removeEmojis(event.target.value);
        this.setState({ query: filteredValue })
    }

    onQuerySubmitClicked = () => {

        this.props?.loaderChange(true);
        let data = {
            "data": {
                "query": this.state.query ?? '',
                "title": this.state?.title ?? '',
                "userid": this.props?.reduxData?.loginDetails?.user?.id,
                "date": new Date().toISOString(),
                "disease_drugID": this.props?.idForAPI ?? ''
            }
        };
        axiosInstance.post(`${process.env.REACT_APP_BASE_URL}api/queries`, data)
            .then((res) => {

                this.setState({ queryPopup: false, querySuccessPopup: true, queryErrorMsg: '' });
            })
            .catch((err) => {
                // Handle authentication error
                this.setState({ queryErrorMsg: 'Something went wrong.Please try after sometime.' });
                console.error("error:", err);

            })
            .finally(() => {
                this.props?.loaderChange(false);
            });
    }

    onCopyClicked = () => {
        navigator.clipboard.writeText(this.state?.urlShare);
        toast(
            "Link copied to a clipboard!",
            {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: "top-right",
                type: "success",
            }
        );
    }

    toggleLike = () => {

        const ids = this.state?.likeData?.attributes?.liked_by?.data?.map(item => item.id);

        this.props?.loaderChange(true);
        if (!this.state?.likeData?.attributes?.liked) {
            ids.push(this.props?.reduxData?.loginDetails?.user?.id)
        } else {
            ids.splice(ids.indexOf(this.props?.reduxData?.loginDetails?.user?.id), 1)
        }

        axiosInstance.put(process.env.REACT_APP_BASE_URL + `api/drug-informations/${this.props?.idForAPI}`, {
            "data": {
                "liked_by": ids
            }
        })
            .then(response => {
                this.likedDataAPI();
            })
            .catch(error => {
            }).finally(() => {
                this.props?.loaderChange(false);
            });

    }

    getLikeCount = (count) => {
        return count > 1 ? count + ' Likes' : count + ' Like';

    };

    render() {

        const { queryPopup, title, query, urlShare, shareDropDownOpen, likeData } = this.state;

        return (
            <>
                <div id='QueryContent'>
                    <div className='d-flex justify-content-between'>
                        <div className="d-flex align-items-start" >
                            <div onClick={this.toggleLike} role="button">

                                {likeData?.attributes?.liked ? <BiSolidLike size={'20px'} color={'#545cd8'} /> : <AiOutlineLike size={'20px'} />}

                            </div>
                            <span className="ms-1" style={{ color: '#333333', fontWeight: 400, fontSize: '1rem' }}>{this.getLikeCount(likeData?.attributes?.liked_by?.data?.length)}</span>
                        </div>
                        <div>

                            <Dropdown isOpen={shareDropDownOpen} toggle={this.toggleDropdown}>
                                <DropdownToggle
                                    tag="div"
                                    data-toggle="dropdown"
                                    aria-expanded={shareDropDownOpen}
                                >
                                    <div className="d-flex align-items-start" role="button">
                                        <img src={shareIcon} alt="" style={{ width: '1rem' }} />
                                        <span className="ms-1" style={{ color: '#333333', fontWeight: 400, fontSize: '1rem' }}>Share</span>
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu style={dropdownMenuStyle}>
                                    <DropdownItem>
                                        <div className="d-flex align-items-center shareMenuItem" onClick={this.onCopyClicked}>
                                            <img src={copyLink} alt="" style={{ width: '32px' }} />
                                            <span className="ms-1" style={{}}>Copy Link</span>
                                        </div>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <EmailShareButton className="shareMenuItem" url={urlShare}> <EmailIcon size={32} round={true} > </EmailIcon> Email</EmailShareButton>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <TwitterShareButton className="shareMenuItem" url={urlShare}> <TwitterIcon size={32} round={true} > </TwitterIcon> Twitter</TwitterShareButton>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <LinkedinShareButton className="shareMenuItem" url={urlShare}> <LinkedinIcon size={32} round={true} > </LinkedinIcon> Linkedin</LinkedinShareButton>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <WhatsappShareButton className="shareMenuItem" url={urlShare}> <WhatsappIcon size={32} round={true} > </WhatsappIcon> Whatsapp</WhatsappShareButton>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <FacebookShareButton className="shareMenuItem" url={urlShare}> <FacebookIcon size={32} round={true} > </FacebookIcon> Facebook</FacebookShareButton>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="drug-Querysubheader mt-3">
                        Have a query ? <span className="text-link" onClick={this.onQueryClicked}>Click here</span> to raise a query.
                    </div>
                </div>

                <Modal isOpen={queryPopup} toggle={this.onQueryClicked} className="centered-modal">
                    <ModalHeader className='QueryModalHeader' toggle={this.onQueryClicked}><span className="QueryModalTitle">Raise Query</span></ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="title">
                                Title
                            </Label>
                            <Input
                                id="title"
                                name="text"
                                placeholder="Enter Title"
                                type="text"
                                minLength={3}
                                maxLength={50}
                                value={title}
                                onChange={this.handleTitleChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleText">
                                Your Query
                            </Label>
                            <Input
                                id="exampleText"
                                placeholder="Enter your query"
                                name="query"
                                type="textarea"
                                rows="4"
                                minLength={3}
                                value={query}
                                onChange={this.handleQueryChange}
                            />
                        </FormGroup>
                    </ModalBody>
                    {this.state.queryErrorMsg &&
                        <div className="error-msg-display QueryModalFooter">
                            {this.state.queryErrorMsg}
                        </div>
                    }
                    <ModalFooter className='QueryModalFooter'>
                        <Button style={{ width: '30%' }} color="primary" onClick={this.onQuerySubmitClicked} disabled={!(title?.length > 2 && query?.length > 2)} >
                            Submit
                        </Button>
                    </ModalFooter>
                </Modal>
                <ToastContainer transition={Slide} />
                <SweetAlert title="Success!" show={this.state.querySuccessPopup}
                    customClass="custom-sweetalert"
                    onConfirm={() => this.setState({ querySuccessPopup: false, queryPopup: false, title: '', query: '', queryErrorMsg: '' })}>
                    Your query has been submitted successfully.
                </SweetAlert>
            </>
        );
    }
}

export default connect(mapStateToProps)(DrugPageLikeQuery);
