import "./polyfills";
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from "./serviceWorker";
import { HashRouter } from "react-router-dom";
import "./assets/base.scss";
import Main from "./Pages/Main";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store'; 

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const renderApp = (Component) => {
  root.render(
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HashRouter>
            <Component />
          </HashRouter>
        </PersistGate>
      </Provider>
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept("./Pages/Main", () => {
    const NextApp = require("./Pages/Main").default;
    renderApp(NextApp);
  });
}

serviceWorker.unregister();
